import { StyleSheet, Text, View } from 'react-native';
import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
// "./node_modules/react";
import { styles } from '../../styles/Standard/game/CountDownTimer';
import { SECONDS_LEFT } from '../../assets/text/eng';

import { playSound } from '../../utils/playSound';
import { timeLowSound } from '../../assets/sounds'
import { useIsFocused } from "@react-navigation/native";

import { shouldStartTimer } from '../../assets/GameState';

// timer logic is here to not have the gamescreen have to rerender every time its timer state changes
export function CountDownTimer(props) {
	const isFocused = useIsFocused();
	const questionTime = props.questionTime;
	const [pausedTime,setPausedTime] = useState(0);
	const [timeLeft, setTimeLeft] = useState(undefined);
	const [timePlayed, setTimePlayed] = useState(0);
	const [startDate, setStartDate] = useState(undefined);
	const [isPaused, setIsPaused] = useState();
	const onVisibilityChange = () => setIsPaused(document.hidden);

	const [playedTimeLowSound, setPlayedTimeLowSound] = useState(false);

	var gameState = require('../../assets/GameState')

	if (gameState.shouldStopTimer == true && timeLeft != undefined && startDate != undefined) {
		stop()
	}
	// if (gameState.shouldStartTimer == true && (timeLeft == undefined || startDate == undefined)) {
	if (gameState.shouldStartTimer == true || gameState.shouldStartTimer == 1) {
		start()
	}

	const calculateTimeLeft = () => {
		const diff = new Date() - startDate;
		let secondsLeft;
		if(pausedTime === 0)
			secondsLeft = questionTime - (Math.floor((diff / 1000))) ;
		else
			secondsLeft = questionTime - (timePlayed);
		return secondsLeft;
	};

	useEffect(() => {
		if((!isPaused && isFocused) || (isPaused === undefined && isFocused)  ){
			setTimePlayed(timePlayed+1);
		}
		//window.addEventListener("visibilitychange", onVisibilityChange);
		if (timeLeft > 0 && startDate) {
			if(!isPaused){
				setTimeout(() => {
					setTimeLeft(calculateTimeLeft());
				}, 1000);
				if (timeLeft < 6 && playedTimeLowSound == false) {
					playSound(timeLowSound);
					setPlayedTimeLowSound(true)
				}
			}
			else{
				setTimeout(() => {
					setPausedTime(pausedTime+1);
				}, 1000);
			}
		} else if (Number.isInteger(timeLeft) && timeLeft <= 0) {
			props.timeUp();
			setPausedTime(0);
			setTimePlayed(0);
			stop()
		}
		return () => {
			//window.removeEventListener("visibilitychange", onVisibilityChange);
		};
	}, [timeLeft, startDate,isPaused,isFocused]);

	function start() {
		setStartDate(+new Date());
		setTimeLeft(questionTime)
		gameState.shouldStartTimer = false;
		setPausedTime(0);
		setTimePlayed(0);
	}

	function stop() {
		if (startDate != undefined || timeLeft != undefined) {
			setStartDate(undefined)
			props.setTimeTaken(timeLeft) //moved 1 up
			setTimeLeft(undefined)
		}
		gameState.shouldStopTimer = false
	}

	var displayedTimeLeft = ""
	if (timeLeft != undefined && !isNaN(timeLeft) && startDate != undefined && gameState.shouldStartTimer == false) {
		if (timeLeft > 0) {
			displayedTimeLeft = timeLeft + " " + SECONDS_LEFT
		} else {
			displayedTimeLeft = "0 " + SECONDS_LEFT
		}
	}

	// clearInterval(this.interval) WHEN DONE

	return (
		<View style={styles.counterContainer}>
			<Text style={styles.text} >{displayedTimeLeft}</Text>
		</View>
	);
}
