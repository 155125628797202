import React, { useState } from "react";
import { Dimensions } from 'react-native';
import { MediaDisplay } from '../components/common/MediaDisplay'
import { Icon } from 'react-native-elements'

export function GameCategoryIntro({ route, navigation }, props) {
    const [gameMediaArray, setGameMediaArray] = useState(undefined)
    const gameState = require('../assets/GameState')
    const windowWidth = Dimensions.get('window').width

    if (gameState.categoryInfo != undefined && !gameMediaArray) {

        navigation.setOptions({ ...navigation.options, headerTitle: gameState.categoryInfo.name })
        var gameMediaObject = gameState.categoryInfo.media;

        if (gameMediaObject != undefined) {
            var mediaArray = []
            Object.keys(gameMediaObject).map(function (key, i) {
                mediaArray.push(gameMediaObject[key])
            })

            setGameMediaArray(mediaArray)
        } else {
            console.log("No game media.")
        }
    }

    return <>
        {
            <MediaDisplay media={gameMediaArray} mustWatchVideo={false} updateHasWatched={false} isLoading={false} isEmptyPage={gameMediaArray == undefined || gameMediaArray.length < 1 ? true : false} />
        }
    </>
}
