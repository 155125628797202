import { StyleSheet, Text, View, Button, Image, Dimensions, ScrollView, Alert, ActivityIndicator } from 'react-native';
import React, { useState } from "react";
import { ListItem } from 'react-native-elements';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

import { styles } from '../../styles/Standard/game/InGameLeaderboard.styles'
import { getElement } from '../../firebase.config';
import { LoadingIndicator } from '../../components/common/LoadingIndicator';
import { images } from '../../assets/images';


const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? windowHeight * 1.2 : windowWidth

export function InGameLeaderboard({ navigation }, props) {

	const [list, setList] = useState(undefined)
	const [isLoading, setIsLoading] = useState(true)
	const [requestCount, setRequestCount] = useState(0)

	const globalState = require('../../assets/GlobalState')
	const currentUserId = (globalState.DBPath.userId != null && globalState.DBPath.userId.length > 0) ? globalState.DBPath.userId : ""

	if (list == undefined && requestCount < 3) {
		if (globalState.DBPath.companyId == "" || globalState.DBPath.departmentId == "") {
			Alert.alert("Company or  department not set");
			navigation.replace('login')
			// logout?
			return null
		}
		setList([])
		var newList = []
		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', 'Leaderboard']
		getElement(path).get().then(function (doc) {
			if (doc.exists) {
				const preProcessedLeaderboard = doc.data()['users']
				var leaderboardKeys = Object.keys(preProcessedLeaderboard)
				leaderboardKeys.sort()
				for (var i = 0; i < leaderboardKeys.length; i++) {
					newList.push(preProcessedLeaderboard[leaderboardKeys[i]])
				}
				setList(newList)
				setIsLoading(false)
			} else {
				console.log("Document not found");
			}
		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
		setRequestCount(requestCount+1)
	}

	// const ownIndex = 0;
	const displayList = (list != undefined) ? list : []/// put list back here

	function renderList() {
		return (displayList.map((l, i) => (
			<ListItem
				key={i}
				leftAvatar={{ source: { uri: '' }, size: heightPercentageToDP(6) }}
				contentContainerStyle={{ marginLeft: 0.03 * windowWidth }}
				title={l.name}
				titleStyle={{ fontSize: heightPercentageToDP(2.3), fontWeight: (l.userId == currentUserId) ? '600' : '400' }}
				badge={{ value: l.score + "%", textStyle: { color: (l.score > 50) ? '#00780C' : '#FF0000', opacity: 0.87, fontWeight: (l.userId == currentUserId) ? '700' : '500', fontSize: heightPercentageToDP(2.5) }, badgeStyle: { backgroundColor: '#FFF' }, containerStyle: { marginTop: 0 } }}
				containerStyle={{ minWidth: cardsContainerWidth * 0.5, marginBottom: 0, maxHeight: windowHeight * 0.1 }}
				bottomDivider
			/>
		)))
	}

	function renderMobileList() {
		return (displayList.map((l, i) => (
			<ListItem
				key={i}
				// leftAvatar={{ source: { uri: '' } }}
				leftAvatar={{ source: (l.imageUrl != undefined)? { uri: l.imageUrl }: images[Math.floor(Math.random() * (images.length - 1))], size: heightPercentageToDP(6) }}
				// contentContainerStyle={{ marginLeft: 0.03 * windowWidth, height: '100%' }}
				contentContainerStyle={{ marginLeft: 0.03 * windowWidth, maxWidth: 0.35 * windowWidth }}
				title={l.name}
				titleStyle={{ fontSize: heightPercentageToDP(3), fontWeight: (l.userId == currentUserId) ? 'bold' : 'medium' }}
				badge={{ value: l.score + "%", textStyle: { color: (l.score > 50) ? '#00780C' : '#FF0000', opacity: 0.87, fontWeight: (l.userId == currentUserId) ? '600' : '500', fontSize: heightPercentageToDP(3) }, badgeStyle: { backgroundColor: '#FFF', padding: 0, margin: 0, minHeight: heightPercentageToDP(7) }, containerStyle: { marginTop: 0, marginRight: windowWidth * 0.02 } }}
				containerStyle={{ minWidth: windowWidth, marginBottom: 0, maxHeight: heightPercentageToDP(10), backgroundColor: (l.userId == currentUserId) ? '#FFF' : '#FFF' }}
				bottomDivider
			/>
		)))
	}

	function customLoadingIndicator() {
		if (isLoading == true) {
		// if (true == true) {
			return (
				<View style={styles.loadingContainer}>
					<View style={styles.loadingCircle}>
						<ActivityIndicator size="large" color="#fff" style={{ marginTop: windowHeight * 0.02 }} />
						<Text style={styles.loadingText}>{"Loading"}</Text>
					</View>
				</View>
			)
		} else {
			return (null)
		}
	}

	return (
		<View style={{minHeight: 100, width: '100%'}}> 

			{/* <LoadingIndicator isLoading={isLoading} /> */}
			
			<ScrollView style={styles.background}>
				<View style={styles.listContainer, { minHeight: (isLoading == true) ? 0.5 * windowHeight : 0 }}> 
					{/* add isloading instead of true */}
					{globalState.portraitView ? renderMobileList() : renderList()}
				</View>
			{customLoadingIndicator()}
			</ScrollView>
			{/* <View style={styles.loadingContainer}>
				<ActivityIndicator size="large" color="#fff" style={{ marginTop: windowHeight * 0.02 }} />
				<Text style={styles.loadingText}>{"Loading"}</Text>
			</View> */}
		</View>
	);
}




