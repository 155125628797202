import React, { useEffect, useState } from "react";
import { View, StyleSheet, TextInput, Text, ScrollView } from "react-native";
import { Dimensions } from "react-native";

const windowWidth = Dimensions.get("window").width;

const Cell = ({ value, position, isHighlighted, isFirstCell, onChangeText }) => (
	<View style={[value == null ? styles.emptyCell : styles.cell, isFirstCell && styles.firstCell, value == null && styles.blackCell]}>
		{value == null ? (
			<Text style={styles.cellInput}></Text>
		) : (
			<View style={styles.smallTextContainer}>
				{isFirstCell && <Text style={styles.smallText}>{position}</Text>}
				<TextInput style={styles.cellInput} value={value} maxLength={1} onChangeText={onChangeText} />
			</View>
		)}
	</View>
);

const CrosswordPuzzle = ({ crosswordData, onCrosswordCorrect, onCrosswordComplete, cols, rows }) => {
	const numCols = cols;
	const numRows = rows;
	const [grid, setGrid] = useState(
		Array(numRows)
			.fill()
			.map(() => Array(numCols).fill(" "))
	);

	const populateGrid = (grid, word, startX, startY) => {
		for (let i = 0; i < word.answer.length; i++) {
			if (word.orientation === "across") {
				grid[startY][startX + i] = {
					value: "",
					answer: word.answer[i],
					isFirstCell: i === 0,
					position: word.position ?? "",
					startX: startX,
					startY: startY,
				};
			} else {
				grid[startY + i][startX] = {
					value: "",
					answer: word.answer[i],
					isFirstCell: i === 0,
					position: word.position ?? "",
					startX: startX,
					startY: startY,
				};
			}
		}
	};

	useEffect(() => {

		let tempGrid = [...grid];

		crosswordData.forEach(({ answer, startx, starty, orientation, position }) => populateGrid(tempGrid, { answer, orientation, position }, startx - 1, starty - 1));

		setGrid(tempGrid);

	}, []);

	const checkCorrect = (grid) => {
		for (let i = 0; i < grid.length; i++) {
			const element = grid[i];
			for (let k = 0; k < element.length; k++) {
				const item = element[k];

				if (item != " " && item?.value?.toLowerCase() != item?.answer?.toLowerCase()) {
					return false;
				}
			}
		}

		return true;
	};

	const checkComplete = (grid) => {
		for (let i = 0; i < grid.length; i++) {
			const element = grid[i];
			for (let k = 0; k < element.length; k++) {
				const item = element[k];

				if (item != " " && !item?.value) {
					return false;
				}
			}
		}

		return true;
	};

	return (
		<View style={{ flexDirection: windowWidth > 600 ? "row" : "column", margin: 50 }}>
			<View style={{ padding: 10, justifyContent: "center", alignItems: "center" }}>
				<Text style={{ fontSize: 24 }}>Accross</Text>
				{crosswordData
					.filter(({ orientation }) => orientation === "across")
					.map((word) => {
						return <Text>{`${word.position}. ${word.clue}`}</Text>;
					})}
				<Text style={{ fontSize: 24 }}>Down</Text>
				{crosswordData
					.filter(({ orientation }) => orientation === "down")
					.map((word) => {
						return <Text>{`${word.position}. ${word.clue}`}</Text>;
					})}
			</View>
			<ScrollView horizontal>
				<View style={styles.container}>
					{grid.map((row, rowIndex) => (
						<View key={`row-${rowIndex}`} style={styles.row}>
							{row.map(({ value, isFirstCell, position }, colIndex) => (
								<Cell
									key={`cell-${rowIndex}-${colIndex}`}
									value={value}
									isHighlighted={false}
									isFirstCell={isFirstCell}
									position={position}
									onChangeText={(newValue) => {
										const newGrid = [...grid];
										newGrid[rowIndex][colIndex].value = newValue;
										setGrid(newGrid);
										onCrosswordCorrect(checkCorrect(newGrid));
										onCrosswordComplete(checkComplete(newGrid));
									}}
								/>
							))}
						</View>
					))}
				</View>
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// borderWidth: 1,
		// borderColor: "#000",
		overflowX: "auto",
		maxWidth: windowWidth
	},
	row: {
		flexDirection: "row",
	},
	cell: {
		width: 40,
		height: 40,
		borderWidth: 1,
		borderColor: "#000",
		justifyContent: "center",
		alignItems: "center",
		margin: 1,
	},
	emptyCell: {
		width: 40,
		height: 40,
		borderWidth: 1,
		borderColor: "transparent",
		justifyContent: "center",
		alignItems: "center",
		margin: 1,
	},
	firstCell: {
		backgroundColor: "#ccc",
	},
	highlightedCell: {
		backgroundColor: "#eee",
	},
	blackCell: {
		// backgroundColor: "#000",
	},
	cellText: {
		fontSize: 20,
		fontWeight: "bold",
	},
	cellInput: {
		width: 40,
		height: 40,
		textAlign: "center",
	},
	smallTextContainer: {
		flexDirection: "row",
		alignItems: "center",
	},
	smallText: {
		position: "absolute",
		top: 1,
		left: 2,
		fontSize: 10,
		color: "gray",
	},
});

export default CrosswordPuzzle;
