import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const cardsContainerWidth = (windowWidth / windowHeight > 1) ? windowHeight * 1.2 : windowWidth;

export var styles = StyleSheet.create({
	pageContainer: {
		backgroundColor: "#FFF",
		width: windowWidth,
	},
	pageTitle: {
		fontSize: hp(5),
		fontWeight: '700', // dekstop only
		textAlign: "center",
		textAlignVertical: "center",
		color: '#000',
		opacity: 0.87,
		minHeight: windowHeight * 0.1,
		marginTop:windowHeight * 0.01,
		marginHorizontal:  windowWidth * 0.04,
		flex: 0.1,
		justifyContent: "center",
	},
	pageDescription: {
		color: '#000',
		opacity: 0.87,
		fontSize: hp('2.50%'),
		marginHorizontal: windowWidth * 0.07,
		flex: 0.5,
		flexShrink: 1,
		flexBasis: 0,
		maxHeight: windowHeight * 0.1,
		minHeight: windowHeight * 0.1,
		textAlign: "center",
		textAlignVertical: "center",
		marginBottom: windowHeight * 0.01,
		overflow:'hidden'
	},
	logo: {
		marginHorizontal: windowWidth * 0.07,
		flex: 1,
		flexShrink: 1,
		flexBasis: 0,
        minHeight: windowHeight * 0.1,
		minWidth: windowWidth * 0.2,
		maxHeight: windowHeight * 0.2,
    },
    logoContainer: {
        maxHeight: windowHeight * 0.20,
        minHeight: windowHeight * 0.20,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
	companyCardsContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		minWidth: windowWidth,
	},
	cardsContainerContent: {
		justifyContent: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
		marginBottom: hp(1)
	},
	cardsContainer: {
		flex: 0.6,
		flexDirection: 'column',
		flexWrap: 'wrap',
		minHeight: windowHeight * 0.6,
		shadowOffset: {
			width: 0,
			height:windowHeight * 0.002
		},
		backgroundColor: '#000',
	},
});

var cardTouchableOpacity = {
	// width: windowWidth * 0.45,
	// // backgroundColor: "#FFF",
	// flex: 1,
	// marginTop: 20,
	// marginHorizontal: windowWidth * 0.02,
	// // marginBottom: windowHeight * 0.02,
	// marginBottom: 10,
	// //needs space for shadow?
	// shadowOffset: {
	// 	width: 0,
	// 	height: windowHeight * 0.002
	// 	//scale these
	// },
	// elevation: windowHeight * 0.005,
	// shadowRadius: windowHeight * 0.004,
	// shadowOpacity: 0.5,
};

const cardStyleJson = { ...cardTouchableOpacity}
export const cardStyle = StyleSheet.create({
	cardTouchableOpacity: cardStyleJson
});