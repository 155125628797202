import React from "react";
import { StyleSheet, Text,  View, ActivityIndicator } from 'react-native';
import { styles, rawStyles } from '../../styles/Standard/common/LoadingIndicator.style';
import { Dimensions } from 'react-native';

export function LoadingIndicator(props) {
    const windowHeight = Dimensions.get('window').height

    const globalColours = require('../../styles/globalColours')

    var updatedStyle = JSON.parse(JSON.stringify(rawStyles))
    updatedStyle.container.backgroundColor = globalColours.primaryColour
    var dynamicStyleSheet = StyleSheet.create(updatedStyle)

    if (props.isLoading == true) {
        return (

            <View style={dynamicStyleSheet.container}>
                <ActivityIndicator size="large" color="#fff" style={{ marginTop: windowHeight * 0.02 }} />
                <Text style={styles.text}>{"Loading"}</Text>
            </View>
        )
    } else {
        return(null)
    }
}