import { ScrollView, Text, View } from 'react-native';
import React, { useState } from "react";

import { styles, rewardsCardStyle } from '../styles/Standard/screens/GamesList.styles'; //rename
import { images, dashboardImages } from '../assets/images';
import { CardButton } from '../components/common/CardButton';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { getElement, getGameSchedules, Firebase } from '../firebase.config';

import {EmptyPageBackground} from '../components/common/EmptyPageBackground'

const isToday = (date) => {
    const today = new Date()
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
};

//TODO test if game works with just 1 game in database

// todo set gamelist to be empty when navigatin away! otherise it will keep previous gamelist
// https://stackoverflow.com/questions/43090884/resetting-the-navigation-stack-for-the-home-screen-react-navigation-and-react-n
export function GamesList({ navigation }, props) {
	const [isLoading, setIsLoading] = useState(true)
	const [requestCount, setRequestCount] = useState(0)
	// const [isEmptyPage]
	const globalState = require('../assets/GlobalState')
	navigation.setOptions({ title: globalState.currentScreenTitle })

	// put in style folder
	const cardBackgroundColours = ['#3C9CD7', '#8C8C8C', '#31B64D', '#FF71E6', "#FF5454", "#FF924A"];
	const cardShadowColours = ['#1F72A6', '#545454', '#108228', '#C242AC', '#EB3C3C', '#FF6500'];
	const gameCardColours = []
	for (var i = 0; i < cardBackgroundColours.length; i++) [
		gameCardColours.push({
			backgroundColour: cardBackgroundColours[i],
			shadowColour: cardShadowColours[i]
		})
	]

	const [gameList, setGamePairedList] = useState(undefined)

	var isEmptyPage = false
	if (isLoading == false && (gameList == undefined || gameList.length < 1)) {
		isEmptyPage = true
	}

	const hasPlayedToday = async (companyId, userId, gameId) => {

		return new Promise((resolve, reject) => {
			let answers = getElement(['Companies', companyId, 'Users', userId, 'Answers'])
				.get();

			answers.then(results => {

				const currentDate = new Date();
				var strDate = currentDate.getFullYear() + '_' + currentDate.getMonth() + '_' + currentDate.getDate();

				results.forEach(res => {

					if (res.id.indexOf(strDate) > -1) {

						let gameData = res.data();

						if (gameData && gameData.gameId && gameData.gameId === gameId) {
							return resolve(true);
						}
					}
				});

				return resolve(false);
			}).catch(error => {
				return resolve(false);
			});
		});
	}


	if (gameList == undefined) {
		const globalState = require('../assets/GlobalState')
		if (requestCount < 3) {
			if (globalState.DBPath.companyId != undefined && globalState.DBPath.departmentId != undefined) {
				var path = ['Companies',globalState.DBPath.companyId, 'Departments',globalState.DBPath.departmentId, 'Games']

				var promises = [];
				var date = new Date()
				var formattedDate = date.toISOString()
				var getGameSchedules = Firebase.functions().httpsCallable('getGameSchedules');

				getGameSchedules({ department: globalState.DBPath.departmentId, company: globalState.DBPath.companyId, currentDate: formattedDate }).then(function (docs) {
					if (docs != undefined && docs.data != undefined && docs.data.games != undefined) {
						var newGameList = []
						docs.data.games.map(game => {
							game['documentId'] = game.gameId
							newGameList.push(game)

							var promise = hasPlayedToday(globalState.DBPath.companyId, globalState.DBPath.userId, game.gameId);
							promises.push(promise);
						})

						Promise.all(promises).then(items => {
							for (var idx in items) {
								newGameList[idx].hasPlayed = items[idx];
							}

							newGameList = unique(newGameList);
							setGamePairedList(assignColours(newGameList))
							setIsLoading(false)
						})
					} else {
						setGamePairedList(assignColours([]))
						setRequestCount(requestCount + 1)
					}

				}).catch(function (error) {
					 setRequestCount(requestCount + 1)
				});
			} else {
				navigation.replace('login')

				// navigation.goBack()
			}
		} else {
			console.log("maximum request reached.")
		}
	}

	function assignColours(list) {
		list.sort((gameA, gameB) => {
			if (gameA.order !== undefined) {
				return gameA.order < gameB.order ? -1 : gameA.order > gameB.order ? 1 : 0;
			}
			return gameA.name < gameB.name ? -1 : gameA.name > gameB.name ? 1 : 0;
		});

		for (var i = 0; i < list.length; i = i + 1) {
			if (list[i] != undefined) {
				var cardColourIndex = Math.floor(Math.random() * (gameCardColours.length -1))
				list[i]["backgroundColour"] = gameCardColours[cardColourIndex].backgroundColour
				list[i]["shadowColour"] = gameCardColours[cardColourIndex].shadowColour
			}
		}
		return list
	}

	function renderGames() {
		var gameListToDisplay = (gameList != undefined) ? gameList : [];

		var lastPlayedIndex = -1;
		let isOrdered = (gameList && gameList.length > 0) ? (gameList[gameList.length - 1].order > 0) : false;
				
		return (gameListToDisplay.map((game, i) => {
			if (game.hasPlayed) {
				lastPlayedIndex = i;
			}
			return (
				<CardButton key={"a" + i} destination='game_intro' navigationData={{ gameInfo: game }} collection={'gameId'}
					document={game['documentId']} title={game.name} description={game.subtitle} isTransparent={isOrdered && lastPlayedIndex !== (i - 1)}
					image={(game.gameImgUrl != undefined) ? { uri: game.gameImgUrl } : images[Math.floor(Math.random() * (images.length - 1))]}
					shadowColour={game.shadowColour} highlight={isOrdered && lastPlayedIndex !== -1 && lastPlayedIndex === (i-1)} backgroundColour={game.backgroundColour} customStyle={rewardsCardStyle} />
			)
		}))
	}

	function renderDesktopText() {
		if (globalState.portraitView) {
			return null
		} else {
			return (
				<View style={styles.gameTextContainer}>
					<Text style={styles.gameTitleStyle}>Games</Text>
					{/* <Text>Click on a game to select a category to start with. All categories will be done in a session. Category's scores add up.</Text> */}
				</View>
			)
		}

	}

	function unique(a) {
		var seen = {};
		return a.filter(function (item) {
			return seen.hasOwnProperty(item.documentId) ? false : (seen[item.documentId] = true);
		});
	}

	return (
		<View style={styles.dashboardContainer}>
			<ScrollView style={styles.scrollView}>
				<View style={styles.textWideContainer}>
					{/* {if(globalState.portraitView) return null } */}
					{renderDesktopText()}
				</View>
				<View style={styles.wideContainer}>

					<View style={styles.cardsContainerContent}>
						{
							renderGames()
						}
					</View>
				</View>
			</ScrollView>
			<LoadingIndicator isLoading={isLoading} />
			<EmptyPageBackground isEmpty={isEmptyPage}/>
		</View>
	);
}


