// To avoid redux and save time
// Also clear in login to be safe, althpugh is cleared before a game starts
module.exports = {
    gameInfo: {
        // is set in gameIntro
        
    },
    categoryInfo: {},
    state: {
        categoryScores: {
            // <categoryId>:<categoryScore>
        },
        uncompletedCategories:
            [/*<categoryId>*/],
        afterGameInfo: [
            // {
            //     questionText: currentQuestion.questionText,
            //     answerText: currentQuestion['answers'][answerIndex],
            //     isCorrect: 0
            // }
        ],
        isLeaderboardUpdated: true
    },
    live: 0,
    localGameLeaderboard: [],
    maximumGameScore: 0,
    shouldStartTimer: false,
    shouldStopTimer: false,
}