import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { isPortraitView } from '../../../utils/isPortraitView'

const portraitView = isPortraitView()

var windowHeight = Dimensions.get('window').height;
var windowWidth = Dimensions.get('window').width;

//web
// export const stylesWeb = StyleSheet.create({
//     gameContainer: {
//         flex: 1,
//         backgroundColor: "#FFFFFF",
//     },
//     answerButtonsContainer: {
//         flex: 1,
//         flexDirection: "column",
//         // set to be from the bottom
//         marginTop: Dimensions.get('window').height * 0.2,
//         // backgroundColor: "#000000",
//         justifyContent: 'space-evenly'
//     },
//     answerButtonRowTop: {
//         flex: 1,
//         // marginTop: ,
//         flexDirection: 'row',
//     },
//     answerButtonRowBottom: {
//         flex: 1,
//         flexDirection: 'row',
//     },
//     image: {
//         // height: 50
//         flex: 0.1
//     }
// });

export const styles = StyleSheet.create({
    answerButtonsContainer: {
        flex: 0.2,
        flexDirection: "column",
        // set to be from the bottom
        marginTop: Dimensions.get('window').height * 0.01,
        // backgroundColor: "#FFF",
        // justifyContent: 'space-evenly'
        justifyContent: 'flex-end',
        minHeight: windowHeight * 0.4,
        width: '100%',
        position: 'absolute',
        bottom: 0

    },
    gameContainer: {
        flex: 1,
        backgroundColor: "#FCEFD2",
        // backgroundColor: "#FFFFFF",
        height: windowHeight,
    },
    answerButtonRowTop: {
        //  backgroundColor: "#FFF",
        flex: 0.5,
        overflow:"hidden",
        // marginTop:  windowHeight * 0.1
        // marginBottom: windowHeight * 0.01,
        marginBottom: windowHeight * 0.0,
        flexDirection: 'row',
        minHeight: hp('8%'),
        // maxHeight: hp('8%'),
    },
    answerButtonRowBottom: {
        // marginTop: windowHeight * 0.15,
        flex: 0.5,
        overflow:"hidden",
        flexDirection: 'row',
        minHeight: hp('8%'),
        // maxHeight: hp('8%'),
        // marginBottom: hp('1%'),
        // backgroundColor: "#FFF",
    },
    questionImageContainer: {
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        flexGrow: 1,
        minHeight:  windowHeight * 0.35,
        marginTop: '1%',
        position: 'relative',
        resizeMode: "contain"
    },
    backgroundImage: {
        // minHeight: windowHeight*1.5,
        height: '100%',
        // maxHeight: windowHeight,
        minWidth: windowWidth,
        maxWidth: windowWidth,
        width: windowWidth,
        position: 'absolute',
        // top: -1 * 300 * windowWidth/windowHeight, //only for FDH
        // top:  ( 0.64 - 0.61 )  * wind  owHeight, //only for FDH
        // top: -180, //only for FDH
        top: 0, //works for all mobile
        // resizeMode: 'center',
        resizeMode: portraitView? 'cover' : 'cover' // cover works perfect for web but not android
    },
    questionText: {
		// textAlign: "center",
		textAlignVertical: "center",
		color: '#000000',
		fontSize: hp('3.5%'),
        // minHeight: windowHeight * 0.1, 
        // minHeight: windowHeight * 0.1, 
        // backgroundColor: "#000000",
        marginBottom: 0,
        marginTop: 0,
		// flex: 0.2,
        justifyContent: "center",
        opacity: 0.87
    },
    questionNumberText: {
        // minHeight: hp('4%'),
        // maxHeight: hp('4%'),
        // marginLeft: windowWidth * 0.1,
        // marginTop:hp('2%'),
        // backgroundColor: '#FFF',
        // marginBottom: windowHeight * 0.01,
        // color: '#000',
        // fontSize: hp('2%'),
        // opacity: 1,

		color: '#000000',
		fontSize: hp(2),
        // minHeight: windowHeight * 0.1, 
        // minHeight: windowHeight * 0.1, 
        // backgroundColor: "#000000",
        marginBottom: 0,
        marginTop: 0,
		// flex: 0.2,
        justifyContent: "center",
        opacity: 0.87,

        // backgroundColor: '#000',
        // minHeight: windowHeight * 0.001
        // min
    },
    questionTextContainer: {
        marginHorizontal: wp('12%'),
    //   flex: 1
        marginTop: hp(5),
        // backgroundColor: '#000'
    // move up when ther is questioncontent?
    },
    headerContainerStyle: {
        minHeight: windowHeight * 0.08,
        maxHeight: windowHeight * 0.08,
        marginTop: windowHeight * 0.05,
        maxWidth: windowWidth,

        // For having timer and leaderboard in same container:
        // flex: 1,
		// flexDirection: 'row',
        // flexWrap: 'wrap',
        // justifyContent: 'flex-end',
        // justifyContent: 'center',
		// height: windowHeight * 0.3,
		// marginBottom: windowHeight * 0.02,
		// maxHeight: windowHeight * 0.4,
		// backgroundColor: "#000",
		// overflow: 'hidden',
        // flexGrow: 1,
        // flexShrink: 1,
        // flexBasis: 0,
    },
    iconContrainer: {
        flex: 1,
        position: 'absolute',
        right: wp(2),
        top: windowHeight * 0.06,
        justifyContent: "center",
        alignItems: 'center',
        maxWidth: 0.15 * windowWidth,
        // backgroundColor: '#555'
        // height: '100%'
    },
    // big questions might push video down too much?
    videoContainer: {
        flex: 1,
		minWidth: windowWidth * 0.5,
        minHeight: windowHeight * 0.2,
        maxHeight: windowHeight * 0.27,
        // height: 500,
        // backgroundColor: '#000',
        marginTop: 0.01 * windowHeight
    },
    webView: {

    }
});

export const button1Style = StyleSheet.create({
    button: {
        // backgroundColor: "#FF5454",
        shadowColor: '#FF5653', 
    }
});

export const button2Style = StyleSheet.create({
    button: {
        backgroundColor: "#549EFF",
        shadowColor: '#5378FF',
    }
});

export const button3Style = StyleSheet.create({
    button: {
        backgroundColor: "#FF5488",
        shadowColor: '#DB3D6C',
    }
});

export const button4Style = StyleSheet.create({
    button: {
        backgroundColor: "#FF924A",
        shadowColor: '#FF6500',
    }
});

