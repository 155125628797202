
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { styles } from '../../styles/Standard/game/GameButton';

export function GameButton(props) {
    const answerIndex = props.answerIndex;

    // can put all these in 1 json and put in style folder
    const defaultForegroundColours = ["#FF5454", "#549EFF", "#FF5488", "#FF924A"]
    const defaultBackgroundColours = ['#EB3C3C', '#5378FF', '#DB3D6C', '#FF6500']

    const defaultFontColor = "#FFF"
    const correctAnswerColour = "#1FC75A"
    const correctAnswerBackgroundColour = "#009936"
    const otherAnswerColour = "#F0DEDE"
    const otherAnswerBackgroundColour = "#DEBEBE"
    const otherAnswerFontColour = "#000"
    const falseFontColour = "#FF0000"

    let foregroundColor = defaultForegroundColours[props.answerIndex]
    let backgroundColor = defaultBackgroundColours[props.answerIndex];
    let fontColor = "#000000";

    if (props.customForegroundColor != undefined && props.customBackgroundColor != undefined) {
        foregroundColor = props.customForegroundColor;
        backgroundColor = props.customBackgroundColor;
    } else {
        switch (props.buttonStatus) {
            case "unanswered":
                foregroundColor = defaultForegroundColours[props.answerIndex];
                backgroundColor = defaultBackgroundColours[props.answerIndex];
                break;
            case "true":
                foregroundColor = correctAnswerColour;
                backgroundColor = correctAnswerBackgroundColour
                break;
            case "false":
                foregroundColor = otherAnswerColour;
                backgroundColor = otherAnswerBackgroundColour;
                fontColor = falseFontColour
                break;
            case "multi-unselected":
                foregroundColor = otherAnswerColour;
                backgroundColor = otherAnswerBackgroundColour;
                fontColor = otherAnswerFontColour;
                break;
            case "grey":
                foregroundColor = otherAnswerColour;
                backgroundColor = otherAnswerBackgroundColour;
                fontColor = otherAnswerFontColour;
                break;
        }
    }

    var opacity = 1
    if (props.isVisible !=undefined && props.isVisible==false) {
        opacity = 0
    }
    //or visible:hidden

    const customShadowStyle = StyleSheet.create({
        view: {
            backgroundColor: (backgroundColor),
            opacity: opacity,
        }
    });

    const customButtonStyle = StyleSheet.create({
        button: {
            backgroundColor: foregroundColor
        }
    });

    const customTextStyle = StyleSheet.create({
        buttonText: {
            color: fontColor
        }
    });
    // backgroundColor:props.shadowColour
    return (
        <View style={[styles.thickShadowView, customShadowStyle.view]}>
            <TouchableOpacity
                style={[styles.button, customButtonStyle.button]}
                activeOpacity={0.5}
                //    rather set styles?
                onPress={() => {
                    props.selectAnswer(answerIndex);
                }}
                disabled={props.disabled}
            >

                <Text style={[styles.buttonText, customTextStyle.buttonText]}> {props.answerOptionText == undefined ? "Option" : props.answerOptionText}</Text>

            </TouchableOpacity>
        </View>
    )
}
