import { generateCrosswordLayout } from "../common/LayoutGenerator";

async function createCrossword(rawData) {

	// Create words array with additional property "placed" 
	
	let words = rawData.map(word => ({
		clue : word.clue,
		answer: word.value
	}));

	let layout = generateCrosswordLayout(words);
	let output = {
		across: {},
		down: {}
	};
	//take the layout and sort it into the across and down objects based on the orientation of the item
	for (let index = 0; index < layout.length; index++) {
		if(layout[index].orientation !== 'none'){
			const item = layout[index];
			output[item.orientation][index+1] = item;
		}
	}

	return {output, layout};
}



export default createCrossword;