//can have multiple files in an Eng directory later. 1 for now
// each constant is defined separately to allow specific import

export const
    //game
    QUESTION = 'Question',
    LOADING_QUESTION = 'Loading question',
    OK = 'OK',
    SECONDS_LEFT = 'seconds left',

    //dashboard
    DASHBOARD_TITLE = 'GameOn',
    GAME_CARD_TITLE = "Get your GameON",
    GAME_CARD_DESCRIPTION = "Test Your Knowledge",
    REWARDS_CARD_TITLE = "Rewards",
    REWARDS_CARD_DESCRIPTION = "Great prizes to be won",
    LEADERBOARD_TITLE = "Scoreboard",
    LEADERBOARD_CARD_DESCRIPTION = "Compare yourself",
    LEARNING_CENTER_TITLE = "Learning Center",
    LEARNING_CENTER_DESCRIPTION = "Prepare Yourself";