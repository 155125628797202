import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? windowHeight * 1.2 : windowWidth

export var styles = StyleSheet.create({
	rewardsUnlockedText: {
		textAlign: "center",
		textAlignVertical: "center",
		flexWrap: 'nowrap',
		color: '#000',
		opacity: 0.87,
		fontSize: 20, //todo scale
		fontWeight: '500',
		maxHeight: windowHeight * 0.1,
		minHeight: windowHeight * 0.1,
		marginVertical: windowHeight * 0.005,
		marginHorizontal: windowWidth * 0.04,
		opacity: 0.87,
		flex: 0.1,
		justifyContent: "center",
		// backgroundColor: '#000',
	},
	departmentDescription: {
		color: '#000',
		opacity: 0.87,
		fontSize: 16,
		marginHorizontal: windowWidth * 0.07,
		marginTop: windowHeight * 0.03,
		flex: 1,
		flexShrink: 1,
		flexBasis: 0,
		maxHeight: windowHeight * 0.13,
		minHeight: windowHeight * 0.13,
		// backgroundColor: "#000",
		// justifyContent: "center"
		// marginBottom: windowHeight * 0.02,
		overflow: 'hidden'
	},
	scrollView: {
		flex: 0.6,
		// flexDirection: 'column',
		maxWidth: '100%',
		width: windowWidth,
		flexDirection: 'row',
		flexWrap: 'wrap',
		// justifyContent: "center",
		// minHeight: windowHeight * 0.6,
		shadowOffset: {
			width: 0,
			height: windowHeight * 0.002
		},
		// marginBottom: windowHeight * 0.02,
		// backgroundColor: '#000',
		paddingTop: hp('1%'),
	},
	wideContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#000',
		justifyContent: 'flex-start',
		// alignItems: 'space-between',
		flexDirection: 'row',
		flexWrap: 'wrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
	},
	cardView: {
		flex: 1,
		marginHorizontal: 0,
		shadowOffset: {
			width: 0,
			height: windowHeight * 0.3,
			//scale these
		},
	},
	cardStyle: {
		marginHorizontal: 0,
		// marginTop: windowHeight * 0.02,
		padding: 0,
		// height: windowHeight * 0.3,
		flex: 1,
	},
	cardImageStyle: {
		marginTop: 0,
		marginHorizontal: 0,
		padding: 0,
		flex: 1,
		height: windowHeight * 0.2,
	},
	flexRow: {
		flex: 2,
		flexDirection: 'row',
		flexWrap: 'wrap',
		// height: windowHeight * 0.3,
		// marginBottom: windowHeight * 0.01,
		minHeight: windowHeight * 0.4,
		maxHeight: windowHeight * 0.4,
		// backgroundColor: "#000",
		overflow: 'hidden',
		flexShrink: 1,
		flexBasis: 0,
	},
	dashboardContainer: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
	}
});

// use start en end radius for the smooth

var cardTouchableOpacity = {
	// width: windowWidth * 0.45,
	// // backgroundColor: "#FFF",
	// flex: 1,
	// marginTop: 20,
	// marginHorizontal: windowWidth * 0.02,
	// // marginBottom: windowHeight * 0.02,
	// marginBottom: 10,
	// //needs space for shadow?
	// shadowOffset: {
	// 	width: 0,
	// height: windowHeight * 0.002
	// minHeight: windowHeight * 0.4
	// 	//scale these
	// },
	// elevation: windowHeight * 0.005,
	// shadowRadius: windowHeight * 0.004,
	// shadowOpacity: 0.5,
};


// const gameCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF5454' }
const gameCardStyleJson = { ...cardTouchableOpacity }

export const gameCardStyle = StyleSheet.create({
	cardTouchableOpacity: gameCardStyleJson
});

// const rewardsCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF5488' }
const rewardsCardStyleJson = { ...cardTouchableOpacity }
export const rewardsCardStyle = StyleSheet.create({
	cardTouchableOpacity: rewardsCardStyleJson
});

// const leaderboardCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF924A' }
const leaderboardCardStyleJson = { ...cardTouchableOpacity }
export const leaderboardCardStyle = StyleSheet.create({
	cardTouchableOpacity: leaderboardCardStyleJson
});