import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View, Dimensions, Image } from 'react-native';

import { YellowBox } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// import BottomTabNavigator from './navigation/BottomTabNavigator';
import {LinkingProp} from './navigation/useLinking';

import { GameScreen } from './screens/GameScreen';
import { Dashboard } from './screens/Dashboard';
import { CompanySelection } from './screens/CompanySelection';
import { Rewards } from './screens/Rewards';
import { GameCategories } from './screens/GameCategories';
import { GameCategoryIntro } from './screens/GameCategoryIntro';
import { GamesList } from './screens/GamesList';
import { Login } from './screens/Login';
import { Leaderboard } from './screens/Leaderboard';
import { Profile } from './screens/Profile';
import { GameIntro } from './screens/GameIntro';
import { LearningCenterTopics } from './screens/LearningCenterTopics';
import { LearningSubTopics } from './screens/LearningSubTopics';
import { LearningMaterial } from './screens/LearningMaterial';
import { Messages } from './screens/Messages';
import { Message } from './screens/Message';

import { RightIconContainer } from './components/common/RightIconContainer';
import { SignOutIcon } from './components/common/SignOutIcon';
import { DoneButton } from './components/game/DoneButton';
import { NavigateIconButton } from './components/common/NavigateIconButton';
import { DASHBOARD_TITLE } from './assets/text/eng';
import { styles, rawStyles } from './styles/Standard/App.style';
import { AfterGame } from './screens/AfterGame';
import AsyncStorage from '@react-native-async-storage/async-storage';

// for  desktop navbar
import { useState, useContext } from "react"
import { I18nManager } from 'react-native';
import { logos } from './assets/images';
import _ from 'lodash';

export const ThemeContext = React.createContext({
	color: '#00FF00',
	setColor: () => { },
});

if (I18nManager.isRTL) {
	console.log("RTL detected, disabling and updating")
	I18nManager.allowRTL(false);
	I18nManager.forceRTL(false);
	Updates.reload();
}


const Stack = createStackNavigator();

export default function App(props) {
	I18nManager.allowRTL(false);
	I18nManager.forceRTL(false);
	const [isLoadingComplete, setLoadingComplete] = React.useState(false);
	const [initialNavigationState, setInitialNavigationState] = React.useState();
	const [initialRouteName, setInitialRouteName] = React.useState('login');
	
	const containerRef = React.useRef();
	//const { getInitialState } = useLinking(containerRef);

	const [color, setColor] = useState('#00FF00')
	const value = { color, setColor }

	const globalColours = require('./styles/globalColours')

	// Load any resources or data that we need prior to rendering the app
	React.useEffect(() => {
		async function loadResourcesAndDataAsync() {
			try {
				SplashScreen.preventAutoHide();

				var initialNavigationState = LinkingProp;//await getInitialState();

				AsyncStorage.getItem('globalState').then(json => {
					
					if (json == null) {

						setInitialNavigationState(initialNavigationState);
						return;
					}

					var res = JSON.parse(json);

					console.log(json);
					console.log(res);

					globalState.DBPath = res.DBPath;
					globalState.companyIdForLoginImage = res.companyIdForLoginImage;
					globalState.userProfile = res.userProfile;

					setInitialNavigationState({ routes: [{ name: 'dashboard' }] });
					setInitialRouteName('dashboard');
				});

				// Load fonts
				await Font.loadAsync({
					...Ionicons.font,
					'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
				});
			} catch (e) {
				// We might want to provide this error information to an error reporting service
				console.warn(e);
			} finally {
				setLoadingComplete(true);
				SplashScreen.hide();
			}
		}

		loadResourcesAndDataAsync();
	}, []);

	// https://stackoverflow.com/questions/44485213/using-firebase-in-react-native-shows-a-timer-warning
	YellowBox.ignoreWarnings(['Warning: ...']);
	console.ignoredYellowBox = ['Setting a timer']
	console.disableYellowBox = true

	const context = useContext(ThemeContext)
	var updatedRawStyles = JSON.parse(JSON.stringify(rawStyles))
	updatedRawStyles.standardHeader.backgroundColor = globalColours.primaryColour

	// console.log("app globalColour = " + globalColours.primaryColour)

	var dynamicStyle = StyleSheet.create(updatedRawStyles)

	const windowWidth = Dimensions.get('window').width
	const windowHeight = Dimensions.get('window').height
	// var isWebDisplay = false;

	const globalState = require('./assets/GlobalState')


	if (Platform.OS != 'android' && Platform.OS != 'ios') {
		globalState.isWebDisplay = true
		if (windowWidth / windowHeight > 0.9) {
			globalState.portraitView = false
		} else {
			// console.log("Setting portrait view1")
			globalState.portraitView = true
		}
	} else {
		// console.log("Setting portrait view2")
		globalState.portraitView = true
		globalState.isWebDisplay = false
	}
	// console.log("Is web display? " + globalState.isWebDisplay)

	const themes = {
		light: {
			foreground: "#000000",
			background: "#eeeeee"
		},
		dark: {
			foreground: "#ffffff",
			background: "#222222"
		}
	};

	if (!isLoadingComplete && !props.skipLoadingScreen) {
		return null;
	} else {		// ______________________________________________________________________________
		return (
			<ThemeContext.Provider value={value}>
				<View style={styles.container}>
					{Platform.OS === 'ios' && <StatusBar barStyle="default" />}
					<NavigationContainer linking={LinkingProp} >
						<Stack.Navigator initialRouteName={initialRouteName}>
							<Stack.Screen name="login" component={Login} options={{ title: 'Login', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff', headerShown: false }} />
							<Stack.Screen 
								name="company_selection" 
								component={CompanySelection} 
								options={{
									title: DASHBOARD_TITLE,
									headerStyle: dynamicStyle.standardHeader,
									headerTitleStyle: styles.standardHeaderTitle,
									headerTintColor: '#fff',
									headerTitle: null,
									headerLeft: () => (<SignOutIcon />)
								}} 
							/>
							{/* <Stack.Screen name="temp_game_screen" component={TempGameScreen} options={{ title: 'TempGameScreen', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff', headerShown: false }} /> */}
							<Stack.Screen name="dashboard" component={Dashboard} options={{
								title: DASHBOARD_TITLE,
								headerStyle: dynamicStyle.standardHeader,
								headerTitleStyle: styles.standardHeaderTitle,
								headerTintColor: '#fff',

								headerTitle: globalState.isWebDisplay?null :
									props =>
										<View style={styles.gameOnLogoContainer}>
											<Image
												style={styles.gameOnLogo}
												activeOpacity={0.9}
												resizeMode="contain"
												source={logos.headerGameOnLogo} />
										 </View>
								,
								headerRight: isLoadingComplete ? () => (
									<RightIconContainer />
								) : null,
								headerLeft: () => (<SignOutIcon />)

							}} />
							<Stack.Screen name="games_list" component={GamesList} options={{ title: 'Games', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="game_intro" component={GameIntro} options={{
								headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff',
								headerRight: () => (
									<NavigateIconButton destination="game_categories" icon="arrow" />
								),
							}} />
							<Stack.Screen name="game_categories" component={GameCategories} options={{ headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="game_category_intro" component={GameCategoryIntro} options={{
								headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff',
								headerRight: () => (
									<NavigateIconButton destination="game" icon="arrow" />
								),
							}} />
							<Stack.Screen name="game" component={GameScreen} options={{ title: DASHBOARD_TITLE, headerStyle: styles.gameHeader, headerTintColor: '#fff', headerShown: false, headerLeft: null }} />
							<Stack.Screen name="after_game" component={AfterGame} options={{
								title: 'Scoreboard', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff',
								headerLeft: null,
								headerRight: () => (
									<DoneButton />
								),
							}} />
							<Stack.Screen name="rewards" component={Rewards} options={{ title: 'Achievements', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="leaderboard" component={Leaderboard} options={{ title: 'Total Leaderboard', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="learning_center_topics" component={LearningCenterTopics} options={{ title: 'Learning Center', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="learning_center_subtopics" component={LearningSubTopics} options={{ title: 'Topic Name', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="learning_center_material" component={LearningMaterial} options={{ title: 'SubTopic Name', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="profile" component={Profile} options={{ title: 'Profile', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="messages" component={Messages} options={{ title: 'Communication', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
							<Stack.Screen name="message" component={Message} options={{ title: 'Message', headerStyle: dynamicStyle.standardHeader, headerTitleStyle: styles.standardHeaderTitle, headerTintColor: '#fff' }} />
						</Stack.Navigator>
					</NavigationContainer>
				</View>
			</ThemeContext.Provider>
		);
	}
}