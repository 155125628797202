import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { backgroundImages } from '../../../assets/images';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export var styles = StyleSheet.create({
	text: {
		textAlign: "center",
		textAlignVertical: "center",
		color: '#000000',
		fontSize: hp('2.5%'),
		marginRight: 0,
		// minHeight: windowHeight*0.1,
		flex: 1,
		justifyContent: "center",
		// marginBottom: windowHeight * 0.1,
		// marginTop: windowHeight * 0.05,
		maxWidth: 0.8 * windowWidth,
	},
	counterContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		// backgroundColor: '#000',
		minWidth: windowWidth * 0.1,
		// minHeight: windowHeight * 0.1
		height: '100%'
		// marginRight: windowWidth * 0.,
		// backgroundColor: '#000',
		// position: 'absolute',
		// left: windowWidth/2 - 0.2*windowWidth
	}
});