import { ScrollView, Text, Image, View, Dimensions, Alert } from 'react-native';
import React, { useState } from "react";

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { styles } from '../styles/Standard/screens/LearningMaterial.styles'; //rename
import { useNavigation } from '@react-navigation/native';
import { dashboardImages, images } from '../assets/images';

import { WebView } from 'react-native-webview';
import { Video } from 'expo-av';
import { Platform } from 'react-native';
import GlobalState, { currentScreenTitle } from '../assets/GlobalState';
import { EmptyPageBackground } from '../components/common/EmptyPageBackground'
import { MediaDisplay } from '../components/common/MediaDisplay'
import { getElement } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';

// ______________________________________________________________________________________________

// import { WebView } from 'expo';
// import React, { CSSProperties } from 'react';

// export function NativeWebViewProps {
//     target: string;
// }

// export const NativeWebView = (props): JSX.Element => {
//     if (Platform.OS === 'web') {
//         return <iframe src={props.target} style={{height: 100, width: 100}} />;
//     }
//     return <WebView source={{ uri: props.target }} />;
// };

// ____________________________________________________________________________________________


const Tab = createMaterialTopTabNavigator();

// https://docs.google.com/gview?embedded=true&url=https://pdfs.semanticscholar.org/257d/c8ae2a8353bb2e86c1b7186e7d989fb433d3.pdf

export function LearningMaterial({ route, navigation }, props) {
    var globalState = require('../assets/GlobalState')
    navigation.setOptions({ title: globalState.currentScreenTitle })
    const [media, setMedia] = useState(undefined) // change
    const [currentMediaPath, setCurrentMediaPath] = useState(undefined)
    const [haveRequestionMedia, setHaveRequestionMedia] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [requestCount, setRequestCount] = useState(0)

    if ((media == undefined || media.length < 1) && isLoading ==true && requestCount < 3) {
        setIsLoading(false)
    }


    var isEmptyPage = false
    if ((media == undefined || media.length < 1) && isLoading == false) {
        isEmptyPage = true
    }


    // var { subTopic } = route.params
    var subTopic
    if (route != undefined && route.params != undefined && route.params.subTopic != undefined) {
        subTopic = route.params.subTopic
    }
    
    const newMediaPath = globalState.DBPath.companyId + "/" + globalState.DBPath.departmentId + "/" + globalState.DBPath.trainingMaterialTopicId + "/" + currentScreenTitle
    if (currentMediaPath == undefined || currentMediaPath != newMediaPath) {
        setCurrentMediaPath(newMediaPath)
    }

    if (subTopic != undefined && media == undefined && subTopic.media !=undefined) {
        var mediaArray = []
        Object.keys(subTopic.media).map(function (key, i) {
            mediaArray.push(subTopic.media[key])
        })
        setMedia(mediaArray)
    }

    if (subTopic == undefined && haveRequestionMedia == false && requestCount < 3) { //request sybtopicmedia hwne coming from  answers
        setRequestCount(requestCount+1)
        if (globalState.DBPath.companyId != undefined && globalState.DBPath.companyId.length > 0 && globalState.DBPath.departmentId != undefined &&
            globalState.DBPath.departmentId.length > 0 && globalState.DBPath.trainingMaterialTopicId != undefined && globalState.DBPath.trainingMaterialTopicId.length > 0 &&
            globalState.DBPath.learningSubTopicId != undefined && globalState.DBPath.learningSubTopicId.length > 0) {
            setHaveRequestionMedia(true)
            var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'TrainingMaterial', globalState.DBPath.trainingMaterialTopicId, "SubTopics", globalState.DBPath.learningSubTopicId]
            getElement(path).get().then(function (doc) {
                if (doc != undefined && doc.data() != undefined) {
                    const subTopicData = doc.data()
                    globalState.currentScreenTitle = (subTopicData.title != undefined)?subTopicData.title : "Topic";
                    if (subTopicData.media != undefined) { // maybe have to change array to object to keep order
                        setMedia(subTopicData.media)
                    } else {
                        isEmptyPage = true
                    }
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    isEmptyPage = true
                }
            }).catch(function (error) {
                setIsLoading(false)
                isEmptyPage = true
            });
        } else {
            Alert.alert("Context information not set.")
            isEmptyPage = true
        }
    }

    if (subTopic == undefined && media !=undefined && globalState.whereToFindAnswer != undefined && globalState.whereToFindAnswer.length > 0) {
        Alert.alert("The answer is at " + globalState.whereToFindAnswer)
        globalState.whereToFindAnswer = ""
    }



    // might only owrk on andoird & ios not web
    function renderWebView() {
        // https://stackoverflow.com/questions/62298683/webview-alternative-for-react-native-web-expo/62300525?noredirect=1#comment110241324_62300525
        // if (Platform.OS === 'web') {
        //     return <iframe src={'www.google.com'} style={{ marginTop: 5, height: 500, width: 1000, minHeight: 400 }} />;
        // }
        if (globalState.isWebDisplay) {
            return (
                <View style={styles.videoContainer} key={i}>
                    <Text style={styles.text}>
                        {/* Youtube Video not supported in web version */}
                </Text>
                </View>
            )
        } else {
            return (
                <WebView
                    originWhitelist={['*']}
                    // source={{ uri:'https://docs.google.com/gview?embedded=true&url=https://alraziuni.edu.ye/uploads/pdf/book1/nursing/fundamentals%20of%20food,nutrition%20and%20diet%20therapy.pdf' }}
                    source={{ uri: 'http://www.google.com' }}
                    style={{ marginTop: 5, height: 500, width: 1000, minHeight: 400 }}
                />)
        }
    }

    function renderMediaItem(media, i) {
        if (media.mediaType == "video" || media.mediaType == "Video") {
            if (media.media == undefined || media.media.length < 3 ||media.media.substring(0,30) != 'https://www.youtube.com/embed/') {
            //     source={{uri: 'https://www.youtube.com/embed/YE7VzlLtp-4' }}
                // Alert.alert("")
                return (
                <View style={styles.videoContainer} key={i}>
                <Text style={styles.text}>
                    {"The YouTube url is invalid.\n The required prefix is https://www.youtube.com/embed/"}
                </Text>
                    </View>
                )
            }

            return (
                <View style={styles.videoContainer} key={i}>
                    <WebView
                        style={styles.WebView}
                        javaScriptEnabled={true}
                        domStorageEnabled={true}
                        // source={{uri: 'www.youtube.com/embed/YE7VzlLtp-4' }}
                        source={{ uri: media.media }}
                        key={"v" + i}
                    />
                </View>
            )
        }
        if (media.mediaType == "text" || media.mediaType == "Text") {
            return (
                <View style={styles.textContainer} key={i}>
                    <Text style={styles.text}>
                        {media.media}
                        {/* {"Small text"} */}
                    </Text>
                </View>
            )
        }

        if (media.mediaType == "image" || media.mediaType == "Image") {
            var img = { uri: media.media }
            return (
                <View style={styles.videoContainer} key={i}>
                    <Image
                        style={styles.image}
                        activeOpacity={1}
                        resizeMode="contain"
                        source={img}
                    />
                </View >
            )
        }
    }



    return (
        <MediaDisplay media={media} isLoading={isLoading} isEmptyPage={isEmptyPage}/>
    )
}

{/* <WebView
source={{ uri: 'https://github.com/expo/expo' }}
style={{ marginTop: 20 }}
/> */}

// https://stackoverflow.com/questions/60592332/reactnative-webview-not-rendering-on-web-but-works-fine-on-android