import { StyleSheet, View, Button, Dimensions, ScrollView, Alert } from 'react-native';
import React, { useState, useEffect } from "react";
import { ListItem, Avatar, Input } from 'react-native-elements';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';

import { primaryColour, primarySideColour } from '../styles/globalColours'
import { styles, inputContainerStyle, buttonColor, buttonShadowColor, loginButtonStyle } from '../styles/Standard/screens/Profile.styles'; //rename
import { getElement, getStorageRef } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { StyledButton } from '../components/common/StyledButton';
// import {   DASHBOARD_TITLE,GAME_CARD_TITLE,GAME_CARD_DESCRIPTION,REWARDS_CARD_TITLE,
//     REWARDS_CARD_DESCRIPTION, LEADERBOARD_CARD_TITLE, LEADERBOARD_CARD_DESCRIPTION
// } from '../assets/text/eng';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { images } from '../assets/images';
import { achievementSound } from '../assets/sounds';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const cardsContainerWidth = (windowWidth / windowHeight > 1) ? 0.5 * windowHeight * 1.2 : windowWidth

// input.current.focus();

// ROBOTO FONT: https://stackoverflow.com/questions/57066075/console-error-fontfamily-roboto-medium-is-not-a-system-font-and-has-not-been

export function Profile({ navigation }, props) {
    const [isLoading, setIsLoading] = useState(true)

    const [isFirstNameInputSelected, setIsFirstNameInputSelected] = useState(false)
    const [isLastNameInputSelected, setIsLastNameInputSelected] = useState(false);

    const [profileData, setProfileData] = useState(undefined)

    // const [newFirstName, setNewFirstName] = useState ((profileData != undefined) ? profileData.userInfo.firstName : 'First Name')
    const [newFirstName, setNewFirstName] = useState('')
    const [newLastName, setNewLastName] = useState("");
    const [imageUrl, setImageUrl] = useState(undefined)
    const [triggerRefresh, setTriggerRefrsh] = useState(false)
    const [libraryPermisisonGranted,setLibraryPermisisonGranted] = useState(false)

    const [defaultProfilePhotoIndex, setDefaultProfilePhotoIndex] = useState(Math.floor(Math.random() * images.length - 1))
    
    const globalState = require('../assets/GlobalState')

    var list = [
        {
            name: 'Games won',
            icon: 'star',
            value: 0,
        },
        {
            name: 'Games played',
            icon: 'games',
            value: 0,
        },
        {
            name: 'Accuracy',
            icon: 'stars',
            value: 0,
        },
        {
            name: 'Achievements',
            icon: 'sentiment-very-satisfied',
            value: 0,
        },
    ]

    // gameStatList needs to be here
    const [gameStatList, setGameStatList] = useState(list)



    var isWeb = false
    if (Constants.platform != 'android' && Constants.platform != 'ios' && windowWidth / windowHeight > 0.9) {
        isWeb = true
    }

    function CapPointsToMax(points, maxPoints) {
        if (points > maxPoints) {
            return maxPoints;
        } else {
            return points;
        }
    }

    if (profileData == undefined) {
        const globalState = require('../assets/GlobalState')
        if (globalState.DBPath == undefined || globalState.DBPath.companyId == undefined ||  globalState.DBPath.userId == undefined || globalState.DBPath.companyId.length < 1 || globalState.DBPath.userId.length < 1) {
            // alert('User or company not set.')
            navigation.replace('login')
            return null
        }
        var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
        getElement(path).get().then(function (doc) {
            if (doc.exists) {
                const newProfileData = doc.data()
                setProfileData(newProfileData)

                if (newProfileData.gameStats != undefined) {
                    const gameStats = newProfileData.gameStats
                    list[0].value = gameStats.gamesWon
                    list[1].value = gameStats.gamesPlayed
                    list[2].value = (gameStats.answerAccuracy != undefined) ? CapPointsToMax(Math.round(gameStats.answerAccuracy * 100), 100) + "%": ""
                    list[3].value = newProfileData.achievements != undefined && newProfileData.achievements.completedAchievements != undefined ? newProfileData.achievements.completedAchievements.length : 0
                    setGameStatList(list)
                }

                setIsLoading(false)
            } else {
                console.log("Document not found");
            }
        }).catch(function (error) {
            console.log("Error getting doc", error);
        });
    }

    //  if input field selected, set 'label' of input  to be e.g. "firstName"
    // todo use correct icons

    var firstNameContainerStyleDefinition = {
        ...inputContainerStyle,
        borderColor: '#a3a3a3',
    }

    const firstNameContainerStyle = StyleSheet.create({
        containerStyle: firstNameContainerStyleDefinition,
        
    });

    var lastNameContainerStyleDefiniton = {
        ...inputContainerStyle,
        borderColor: '#a3a3a3',
        // opacity: 0.38
    }

    const lastNameContainerStyle = StyleSheet.create({
        containerStyle: lastNameContainerStyleDefiniton
    });

    var firstNameInputProps = (isFirstNameInputSelected) ? {
        label: 'First Name',
        // labelStyle: { opacity: 0, backgroundColor: '#FFF', width: windowWidth * 0.1, marginTop: -10, marginBottom: 0, color: '#FF5454', fontSize: wp(3) }
        labelStyle: { opacity: 0, backgroundColor: '#FFF', width: windowWidth * 0.1, marginTop: -10, marginBottom: 0, color: '#FF5454', fontSize: wp(10) } //for iphone
    } : {}

    var lastNameInputProps = (isLastNameInputSelected) ? {
        label: 'Last Name',
    } : {}

    function setFirstNameLastName() {
        //  test if typed data differs from rpifoledata first

        const newProfileData = profileData
        newProfileData.userInfo.firstName = newFirstName
        newProfileData.userInfo.lastName = newLastName
        // newProfileData. learningCentreProgress = {
        //     examsTaken: [],
        //     subTopicsRead: [
        //         // "topicid_subtopicid"
        //     ]
        // }
        setProfileData(newProfileData)
        var path = ['Companies', companyName, 'Users', userID]
        getElement(path).set({
            newProfileData
        });
    }

    const [image, setImage] = useState(null);

    useEffect(() => {
        (async () => {
            if (Constants.platform.ios == true && libraryPermisisonGranted == false) {
                const { status } = await ImagePicker.requestCameraPermissionsAsync();
                if (status !== 'granted') {
                    alert('GameOn uses photo library access for users to upload and display their profile picture in leaderboards. Permission is required for this feature.');
                } else {
                    setLibraryPermisisonGranted(true)
                }
            }
        })();
    }, []);

    // async function requestPermission

    const pickImage = async () => {
        if ( (Constants.platform.ios != true) || libraryPermisisonGranted==true) {
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images, // make sure only supported formats acan be selected
                allowsEditing: true,
                aspect: [4, 4],
                quality: 0.5, // use some compression like 0.5?
                base64: true //this?
            });
    
            if (!result.cancelled) {
                setImage(result.uri);
                uploadImageAsync(result.uri)
            }
        } else {
            Alert.alert("Permission not granted. Grant permission and try again")
        }
       

        //limit size
    };

    // expo/examples
    async function uploadImageAsync(uri) {
        // Why are we using XMLHttpRequest? See:
        // https://github.com/expo/expo/issues/2402#issuecomment-443726662
        const blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest()
          xhr.onload = function() {
            resolve(xhr.response)
          };
          xhr.onerror = function(e) {
            reject(new TypeError('Network request failed'))
          };
          xhr.responseType = 'blob'
          xhr.open('GET', uri, true)
          xhr.send(null)
        });
      
        const filename = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) +Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
        const imgPath = 'user_profile_images/' + filename
        const ref = getStorageRef('user_profile_images').child(filename)
        const snapshot = await ref.put(blob)
      
        // We're done with the blob, close and release it
        if (globalState.portraitView == 'true') {
            blob.close()
        }
      
        // return await snapshot.ref.getDownloadURL();
        await snapshot.ref.getDownloadURL().then(function (url) {
            var profilePath = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
            var newProfileData = profileData
            newProfileData.userInfo.imageUrl = url
            newProfileData.userInfo.imagePath = imgPath
            try {
                getElement(profilePath).set(newProfileData); 
                setProfileData(newProfileData)
                setTriggerRefrsh(triggerRefresh)
                Alert.alert("Profile picture uploaded")
            } catch (e) {
                console.log(e)
            }
        });
      }


    // Nb when only 1 field changed
    function submitNames(newFirstName, newLastName) {
        try {
            
            var newProfileData = profileData
            newProfileData.userInfo.firstName = (newFirstName.length > 0) ? newFirstName : profileData.userInfo.firstName
            newProfileData.userInfo.lastName = (newLastName.length > 0) ? newLastName : profileData.userInfo.lastName
            var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
        getElement(path).set(newProfileData)
        globalState.userProfile = newProfileData
            Alert.alert("Your profile change is submitted.")
        } catch (e) {
            console.log(e)
        }
    }

    function renderMobileProfile() {
        return (
            <ScrollView keyboardShouldPersistTaps='handled' style={{ width: windowWidth, height: windowHeight }}>
                <View style={styles.logoContainer}>
                    <Avatar
                        // style={styles.avatar}
                        size={hp(25)}
                        rounded
                        showEditButton
                        onPress={pickImage}
                        source={(profileData != undefined && profileData.userInfo != undefined && profileData.userInfo.imageUrl != undefined && profileData.userInfo.imageUrl.length > 0) ? {
                            uri: profileData.userInfo.imageUrl,
                        } : images[defaultProfilePhotoIndex]}
                    />
                </View>
                {/* inputStyle={{ fontSize: hp(3) }} */}
                {/* inputStyle={{fontSize: hp(4)}} */}
                <Input  {...firstNameInputProps} placeholder={(profileData != undefined && profileData.userInfo != undefined &&
                    profileData.userInfo.firstName != undefined) ? profileData.userInfo.firstName : 'First Name'}
                    inputStyle={{ color: '#000', fontSize: hp(3), height: (isWeb) ? hp(6) : '100%' }}
                    onChangeText={typedText =>
                        setNewFirstName(typedText)}
                    containerStyle={firstNameContainerStyle.containerStyle}
                    inputContainerStyle={{ borderBottomWidth: 0, marginTop: 0 }} />
                {/* inputStyle={{ color: '#000' }} */}
                {/* <Input  {...firstNameInputProps} onChangeText={typedText => setNewFirstName(typedText)} containerStyle={firstNameContainerStyle.containerStyle} inputContainerStyle={{ borderBottomWidth: 0, marginTop: 0 }} /> */}
                <Input  {...lastNameInputProps} placeholder={(profileData != undefined) ? profileData.userInfo.lastName : 'Last Name'}
                    onChangeText={typedText => setNewLastName(typedText)} inputStyle={{
                        color: '#000', fontSize: hp(3),
                        height: (isWeb) ? hp(6) : '100%'
                    }} containerStyle={lastNameContainerStyle.containerStyle} inputContainerStyle={{ borderBottomWidth: 0, marginTop: 0 }} />
                <View style={{ height: windowHeight * 0.12 }}>
                    <StyledButton text='Update' onPressFunction={submitNames} newFirstName={newFirstName} newLastName={newLastName}
                        backgroundColor={primaryColour} shadowColour={primarySideColour}></StyledButton>
                </View>
                <View style={styles.listContainerStyle}>
                    {
                        gameStatList.map((l, i) => (
                            <ListItem
                                key={i}
                                leftIcon={{ name: l.icon, color: primaryColour, size: hp(4) }}   //, iconStyle:{ minHeight: hp(5)} }}
                                // rightAvatar={{ src: { uri: l.icon } }}
                                title={l.name}
                                badge={{ value: l.value, textStyle: { color: '#000', opacity: 0.87, fontSize: hp(2) }, badgeStyle: { backgroundColor: '#FFF' }, containerStyle: { marginTop: 0 } }}
                                // containerStyle={{ minWidth: windowWidth * 0.9, marginBottom: 0, maxHeight: windowHeight * 0.08, marginTop: 1 }}
                                containerStyle={{
                                    minWidth: windowWidth
                                    , marginBottom: 0, marginTop: 0, padding: hp(1)
                                }} //maxHeight: hp(7),
                                titleStyle={{ fontSize: hp(2.3) }}
                                bottomDivider
                            />
                        ))
                    }

                </View>
            </ScrollView>
        )
    }

    function renderWebProfile() {
        return (
            <View style={styles.profileContainer}>
                <View style={styles.logoContainer}>
                    <Avatar
                        // style={styles.avatar}
                        size={hp(20)}
                        rounded
                        showEditButton
                        onPress={() => {
                            pickImage();
                        }}
                        source={(profileData != undefined && profileData.userInfo != undefined && profileData.userInfo.imageUrl != undefined && profileData.userInfo.imageUrl.length > 0) ? {
                            uri: profileData.userInfo.imageUrl,
                        } : images[defaultProfilePhotoIndex]}
                    />
                </View>

                <Input  {...firstNameInputProps} placeholder={(profileData != undefined && profileData.userInfo != undefined &&
                    profileData.userInfo.firstName != undefined) ? profileData.userInfo.firstName : 'First Name'} containerStyle={firstNameContainerStyle.containerStyle}
                    onChangeText={typedText => setNewFirstName(typedText)}
                    inputContainerStyle={{ borderBottomWidth: 0, marginTop: 0 }}   inputStyle={{ color: '#000', fontSize: hp(3), height: (isWeb) ? hp(6) : '100%' }}/>
                <Input  {...lastNameInputProps} placeholder={(profileData != undefined && profileData.userInfo != undefined &&
                    profileData.userInfo.lastName != undefined) ? profileData.userInfo.lastName : 'Last Name'}
                    onChangeText={typedText => setNewLastName(typedText)}
                    containerStyle={lastNameContainerStyle.containerStyle}
                    inputContainerStyle={{ borderBottomWidth: 0, marginTop: 0 }}  inputStyle={{ color: '#000', fontSize: hp(3), height: (isWeb) ? hp(6) : '100%' }} />
                <View style={{ height: windowHeight * 0.12 }}>
                    <StyledButton text='Update' onPressFunction={submitNames} newFirstName={newFirstName} newLastName={newLastName} backgroundColor={primaryColour}
                        shadowColour={primarySideColour}></StyledButton>
                </View>
                <View style={styles.listContainerStyle}>
                    {
                        gameStatList.map((l, i) => (
                            <ListItem
                                key={i}
                                leftIcon={{ name: l.icon, color: primaryColour, size: hp(3) }}
                                // rightAvatar={{ src: { uri: l.icon } }}
                                title={l.name}
                                titleStyle={{ fontSize: hp(2.3), marginLeft: cardsContainerWidth * 0.1 }}
                                badge={{ value: l.value, textStyle: { color: '#000', opacity: 0.87, fontSize: hp(3) }, badgeStyle: { backgroundColor: '#FFF' }, containerStyle: { marginTop: 0 } }}
                                containerStyle={{ minWidth: cardsContainerWidth, marginBottom: 0, maxHeight: windowHeight * 0.08 }}
                                bottomDivider
                            />
                        ))
                    }

                </View>
            </View>
        )
    }

    return (
        <View style={styles.dashboardContainer}>
            <View style={styles.wideContainer}>
                <View style={styles.cardsContainerContent}>
                    {globalState.portraitView ? renderMobileProfile() : renderWebProfile()}
                    <LoadingIndicator isLoading={isLoading} />
                </View>
            </View>
        </View>
    );
}


