import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Button, Platform, Alert } from 'react-native';
import { Firebase } from '../../firebase.config';

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

export function PushNotification() {
    //return null
    const globalState = require('../../assets/GlobalState')
    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification] = useState(false);
    const notificationListener = useRef();
    const responseListener = useRef();
    const [isRegistered, setIsRegistered] = useState(false);


    if (globalState.isWebDisplay) {
        console.log("this module is breaking web.")
        console.log("notifications not supported on web yet")
        return null
    }

    useEffect(() => {

        registerForPush();

        // This listener is fired whenever a notification is received while the app is foregrounded
        notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
            setNotification(notification);
        });

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
            console.log(response);
        });

        return () => {
            // I removed
            // Notifications.removeNotificationSubscription(notificationListener);
            // Notifications.removeNotificationSubscription(responseListener);
        };
    }, []);

    async function registerForPushNotificationsAsync() {

        let token;
        if (Constants.isDevice) {
            const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)
                    .catch(e => {
                        console.log("Failed to ask permissions!");
                    })
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                console.log("failed to get push notifications permissions!");
                return;
            }

            token = (await Notifications.getExpoPushTokenAsync()).data;

            var storeToken = Firebase.functions().httpsCallable('storePushNotificationToken');

            const succeed = await storeToken({ dbContext: globalState.DBPath, token: token })
                .then(function (response) {
                    console.log("submitted token");
                })
                .catch(error => {
                    console.log("uploadToken, ", error);
                })
        } else {
            console.log("Push notifications not registered, a real device should be used for push notifications.");
        }

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                enableVibrate: true,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C'
            });
        }

        return token;
    }

    const registerForPush = () => {
        if (!isRegistered) {
            try {
                registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
            } catch (e) {
                var logErrors = Firebase.functions().httpsCallable('logErrors'); //can this be executed without being logged in ?
                const globalState = require('../../assets/GlobalState')
                logErrors({
                    dbContext: globalState.DBPath, error: e
                })
            }
            setIsRegistered(true)
        } else {
            // Alert.alert("already registered")
        }
    }

    return (
        <View
            style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-around',
            }}>
            
        </View>
    );
}