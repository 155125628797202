import { StyleSheet, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

import { primaryColor } from '../../globalColours'
import {isPortraitView} from '../../../utils/isPortraitView'

const portraitView = isPortraitView()


const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

const cardsContainerWidth = (!portraitView && windowWidth / windowHeight > 1) ? 0.5* windowHeight * 1.2 : windowWidth

export var styles = StyleSheet.create({
	listContainerStyle: {

	},
	background: {
		height: windowHeight,
		backgroundColor: '#FFF',
		// width: '100%',
		// minHeight: windowHeight * 0.8,
	},
	areasTitle: {
		marginLeft: windowWidth * 0.1,
		marginTop: windowHeight * 0.02,
		fontSize: 16,
	},
	areaTextStyle: {
		color: primaryColor,
	},
	wideContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#00F',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		alignItems: 'center',
		// alignItems: 'space-between',
		// flexDirection: portraitView?'row': 'column',
		flexDirection: portraitView?'column': 'column',
		flexWrap: portraitView?'wrap':'nowrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
		minHeight: windowHeight,
		maxHeight: windowHeight
	},
});

// use start en end radius for the smooth

