
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { styles } from '../../styles/Standard/game/QuestionAnsweredBanner';

export function MessageBanner({ displayMessage, message, isErrorMessage }) {
    
	const updatedTextStyle = StyleSheet.flatten([
		styles.roundBackground,
		{
			backgroundColor: (isErrorMessage === true) ? "#FF5454" : "#1FC75A",
			display: (displayMessage === true) ? "flex" : "none"
		}
	]);

	return (
		<View style={styles.view}>
			<View style={updatedTextStyle}>
				<Text style={styles.text}>
                    {message}
				</Text>
			</View>
		</View>
	);
}


