import React, { useState, useEffect } from "react";
import { Text, View } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MediaDisplay } from '../components/common/MediaDisplay'
import { getElement } from '../firebase.config';
import { styles } from '../styles/Standard/screens/GameIntro.styles';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { NavigateIconButton } from '../components/common/NavigateIconButton';
import gameState from "../assets/GameState";

const Tab = createMaterialTopTabNavigator();

export function GameIntro({ route, navigation }, props) {

    const [isLoading, setIsLoading] = useState(true);
    const [playedToday, setPlayedToday] = useState(null)
    const [selectedGameInfo, setSelectedGameInfo] = useState(undefined)
    const [gameMediaArray, setGameMediaArray] = useState(undefined)
    const globalState = require('../assets/GlobalState')
    const gameState = require('../assets/GameState')
    const ALLOWED_PLAYS_PER_DAY = globalState.userGameRetryOverride > 1 ? globalState.userGameRetryOverride : gameState.gameInfo.gameRetries ? parseInt(gameState.gameInfo.gameRetries) : 1;

    useEffect(() => {

        navigation.setOptions({ ...navigation.options, headerRight: null });
    
        loadGameState();
        loadAnswers()
            .then((answers) => {
                loadGameCategories(answers)
                .then(() => setIsLoading(false))
                .catch((error) => setIsLoading(false));
            })
            .catch((error) => setIsLoading(false));

    }, []);

    const updateHasWatched = (val) => {
        gameState.gameInfo.hasWatchedVideo = val;
    }

    const getCategoryPlayedToday = (userAnswers, gameId, categoryId) => {

        var timesPlayedToday = 0;
        const currentDate = new Date();
        var strDate = currentDate.getFullYear() + '_' + currentDate.getMonth() + '_' + currentDate.getDate();

        for (var answerKey in userAnswers) {

            let answerData = userAnswers[answerKey];

            if (answerData.id.indexOf(strDate) > -1) {

                if (answerData && answerData.gameId && answerData.gameId === gameId && answerData.categoryId && answerData.categoryId === categoryId) {
                    timesPlayedToday++;
                }
            }
        }

        return timesPlayedToday;
    }

    const loadGameState = () => {
        
        return new Promise((res, rej) => {

            if (route.params == undefined) {
                navigation.replace('dashboard')
                rej(null);
                return;
            }
    
            if (selectedGameInfo == undefined) {
                if (route.params == undefined && globalState.gameInfo != undefined) {
    
                    // setSelectedGameInfo(globalState.gameInfo)
                } else if (route.params != undefined) {
                    const { gameInfo } = route.params
                    var newGameInfo = gameInfo
                    globalState.gameInfo = newGameInfo // should be gamestate but working atm
                    if (gameState.gameInfo == undefined) {
                        gameState.gameInfo = {
                            maximumScore: 0,
                        }
                    }
                    if (gameInfo.maximumCorrect != undefined) {
                        gameState.gameInfo.maximumScore = gameInfo.maximumCorrect
                        gameState.gameInfo.maximumGameScore = gameInfo.maximumCorrect
                        gameState.maximumScore = gameInfo.maximumCorrect
                    } else {
                        console.log("game's maximumscore undefined")
                    }
                    setSelectedGameInfo(gameInfo)
                    gameState.gameInfo.name = gameInfo.name;
                    gameState.gameInfo.mustWatchVideo = gameInfo.mustWatchVideo;
                    gameState.gameInfo.hasWatchedVideo = !gameInfo.mustWatchVideo;
                    gameState.gameInfo.gameRetries = gameInfo.gameRetries;
                    gameState.gameInfo.categoryRetries = gameInfo.categoryRetries;
    
                    var gameMediaObject = gameInfo.media
                    if (gameMediaObject != undefined) {
                        var mediaArray = []
                        Object.keys(gameMediaObject).map(function (key, i) {
                            mediaArray.push(gameMediaObject[key])
                        })
                        setGameMediaArray(mediaArray)
                    } else {
                        console.log("No game media.")
                        // navigation.navigate('game_categories')
                    }
                }
    
            }

            res(true);

        });

    }

    const loadAnswers = () => {

        return new Promise((res, rej) => {

            getElement(['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId, 'Answers'])
            .get()
            .then(results => {

                var answers = []

                results.docs.map(doc => {
                    var answer = doc.data();

                    answer.id = doc.id;

                    answers.push(answer);
                });

                res(answers);

            })
            .catch(error => {

                rej(error);

            });

        });
    };

    const loadGameCategories = (userAnswers) => {

        return new Promise((res, rej) => {

            if (
                !globalState?.DBPath || !globalState?.DBPath?.companyId || !globalState?.DBPath?.departmentId || !globalState?.DBPath?.gameId ||
                globalState.DBPath.companyId.length < 1 || globalState.DBPath.departmentId.length < 1 || globalState.DBPath.gameId.length < 1
            ) {

                navigation.replace('login');
                rej("Invalid database path");
                return null;

            }
    
            var categoryPlayedTheLeast = 100;
    
            var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Games', globalState.DBPath.gameId, 'Categories']
    
            getElement(path).get().then(function (docs) {
    
                if (docs != undefined && docs.docs != undefined) {
    
                    docs.docs.map((category, idx) => {
    
                        var timesPlayed = getCategoryPlayedToday(userAnswers, globalState.DBPath.gameId, category.id);
    
                        if (timesPlayed < categoryPlayedTheLeast) {
                            categoryPlayedTheLeast = timesPlayed;
                        }
                    });
                }
                if (categoryPlayedTheLeast >= ALLOWED_PLAYS_PER_DAY) {
                    setPlayedToday(true);
                } else {
                    setPlayedToday(false);
                }
                
                setIsLoading(false);
                res(true);
    
            });
    
        });

    }

    useEffect(() => {
        if (!isLoading) {
            if (playedToday === false) {
                
                navigation.setOptions({ 
                    ...navigation.options,
                    headerRight: () => (
                        <NavigateIconButton destination="game_categories" icon="arrow" />
                    ) 
                });
            
            }
        }

    }, [isLoading, playedToday]);

    return <>
        {
            isLoading
            ?
                <View style={styles.textContainer}>
                    <LoadingIndicator isLoading={isLoading} />
                </View>
            :
                !playedToday
                ?
                    <MediaDisplay media={gameMediaArray} mustWatchVideo={gameState.gameInfo.mustWatchVideo} updateHasWatched={updateHasWatched} isLoading={isLoading} isEmptyPage={gameMediaArray == undefined || gameMediaArray.length < 1 ? true : false} />
                :
                    <View style={styles.textContainer}>
                        <Text style={styles.textAlreadyPlayed}>You've already played this game today. Please check again tomorrow.</Text>
                    </View>
        }
    </>
}
