import { ScrollView, Text, Image, View, Dimensions, Alert } from 'react-native';
import React, { useState } from "react";

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { styles } from '../styles/Standard/screens/LearningMaterial.styles'; //rename
import { useNavigation } from '@react-navigation/native';
import { dashboardImages, images } from '../assets/images';

import { WebView } from 'react-native-webview';
import { Video } from 'expo-av';
import { Platform } from 'react-native';
import GlobalState, { currentScreenTitle } from '../assets/GlobalState';
import { EmptyPageBackground } from '../components/common/EmptyPageBackground'
import { getElement } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { MediaDisplay } from '../components/common/MediaDisplay'

// ______________________________________________________________________________________________

// import { WebView } from 'expo';
// import React, { CSSProperties } from 'react';

// export function NativeWebViewProps {
//     target: string;
// }

// export const NativeWebView = (props): JSX.Element => {
//     if (Platform.OS === 'web') {
//         return <iframe src={props.target} style={{height: 100, width: 100}} />;
//     }
//     return <WebView source={{ uri: props.target }} />;
// };

// ____________________________________________________________________________________________


const Tab = createMaterialTopTabNavigator();

// https://docs.google.com/gview?embedded=true&url=https://pdfs.semanticscholar.org/257d/c8ae2a8353bb2e86c1b7186e7d989fb433d3.pdf

export function Message({ route, navigation }, props) {
    var globalState = require('../assets/GlobalState')
    const [media, setMedia] = useState(undefined) // change
    const [currentMediaPath, setCurrentMediaPath] = useState(undefined)
    const [haveRequestionMedia, setHaveRequestionMedia] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    
    // var { message } = route.params
    var message
    if (route != undefined && route.params != undefined && route.params.message != undefined) {
        message = route.params.message
    }
    navigation.setOptions({ title: message !=undefined ? message.title: "Communication" })

    var isEmptyPage = false
    if (media == undefined || media.length < 1) {
        isEmptyPage = true
    }
    
    const newMediaPath = globalState.DBPath.companyId + "/" + globalState.DBPath.departmentId + "/" + globalState.DBPath.trainingMaterialTopicId + "/" + currentScreenTitle
    if (currentMediaPath == undefined || currentMediaPath != newMediaPath) {
        setCurrentMediaPath(newMediaPath)
    }

    if (message != undefined && media == undefined && message.media !=undefined) {
        var mediaArray = []
        Object.keys(message.media).map(function (key, i) {
            mediaArray.push(message.media[key])
        })
        setMedia(mediaArray)
    }

    // if (message == undefined && haveRequestionMedia == false) { //request sybtopicmedia hwne coming from  answers
    //     if (globalState.DBPath.companyId != undefined && globalState.DBPath.companyId.length > 0 && globalState.DBPath.departmentId != undefined &&
    //         globalState.DBPath.departmentId.length > 0 && globalState.DBPath.trainingMaterialTopicId != undefined && globalState.DBPath.trainingMaterialTopicId.length > 0 &&
    //         globalState.DBPath.learningSubTopicId != undefined && globalState.DBPath.learningSubTopicId.length > 0) {
    //         setHaveRequestionMedia(true)
    //         var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'TrainingMaterial', globalState.DBPath.trainingMaterialTopicId, "SubTopics", globalState.DBPath.learningSubTopicId]
    //         getElement(path).get().then(function (doc) {
    //             if (doc != undefined && doc.data() != undefined) {
    //                 const messageData = doc.data()
    //                 globalState.currentScreenTitle = (messageData.title != undefined)?messageData.title : "Topic";
    //                 if (messageData.media != undefined) { // maybe have to change array to object to keep order
    //                     setMedia(messageData.media)
    //                 } else {
    //                     isEmptyPage = true
    //                 }
    //                 setIsLoading(false)
    //             } else {
    //                 setIsLoading(false)
    //                 isEmptyPage = true
    //             }
    //         }).catch(function (error) {
    //             setIsLoading(false)
    //             isEmptyPage = true
    //         });
    //     } else {
    //         Alert.alert("Context information not set.")
    //         isEmptyPage = true
    //     }
    // }



    return (
        <MediaDisplay media={media} isLoading={isLoading} isEmptyPage={isEmptyPage}/>   
    )
}


// https://stackoverflow.com/questions/60592332/reactnative-webview-not-rendering-on-web-but-works-fine-on-android