import React from "react";
import { Text, Image, View } from 'react-native';
import { styles } from '../../styles/Standard/common/EmptyPageBackground.style';
import {emptyPage } from '../../assets/images';


export function EmptyPageBackground(props) {

    if (props.isEmpty == true) {
        return(
        <View style={styles.emptyBackground}>
        <View style={styles.emptyContentContainer}>
            <Image source={emptyPage} style={styles.emptyScreenImage}></Image>
            <View style={styles.emptyTextContainer}>
                <Text style={styles.emptyText}>No content yet</Text>
                </View>
        </View>
            </View>
        )
    } else {
        return(null)
    }
}