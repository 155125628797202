
import React, { Fragment } from "react";
import { useNavigation } from '@react-navigation/native';
import { Icon, Tooltip } from 'react-native-elements'
import { Dimensions, Text, Platform, View } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { styles } from '../../styles/Standard/App.style';

export function NavigateIconButton(props) {
    const windowWidth = Dimensions.get('window').width
    const navigation = useNavigation();
    const gameState = require('../../assets/GameState');

    const getIcon = () => {
        return <Icon name="play-arrow" size={hp(7)} color='#FFF'
            onPress={() => navigation.replace(props.destination)}
            iconStyle={{ marginRight: windowWidth * 0.06 }} />;
    }

    return (

        <Fragment>
            {gameState.gameInfo.hasWatchedVideo && (Platform.OS == "ios" || Platform.OS == "android") &&
                <Tooltip popover={<Text>{"Press play"}</Text>}>
                {getIcon()}
                </Tooltip>
            }
            {gameState.gameInfo.hasWatchedVideo && Platform.OS == "web" &&
                <View style={styles.rowContainer}>
                <Text style={styles.righHeaderStyle}>{"Press play"}</Text>
                    {getIcon()}
                </View>
            }
        </Fragment>
    )
}
