import * as Linking from 'expo-linking';

export default function(containerRef) {
  return {
    prefixes: [Linking.makeUrl('/')],
    config: {
      path: 'root',
      screens: {
        Home: 'home',
        Links: 'links',
        Settings: 'settings',
        Game: 'game',
        Dashboard: 'dashboard'
      },
    },
  };
}

export const LinkingProp = {
    prefixes: [Linking.makeUrl('/')],
    config: {
      screens: {
        Home: 'home',
        Links: 'links',
        Settings: 'settings',
        Game: 'game',
        Dashboard: 'dashboard'
      },
    }
};