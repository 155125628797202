
import React from "react";
import { StyleSheet, Text, View } from 'react-native';
import { styles } from '../../styles/Standard/common/ErrorDisplay.style';
import { useNavigation } from '@react-navigation/native';
import { Dimensions } from 'react-native';
import Constants from 'expo-constants';

export function ErrorDisplay(props) {
    const windowWidth = Dimensions.get('window').width
    const windowHeight = Dimensions.get('window').height

    var isWeb = false
    if (Constants.platform != 'android' && Constants.platform != 'ios' && windowWidth / windowHeight > 0.9) {
        isWeb = true
    }

    const dynamicErrorStyle = StyleSheet.create({
        container: {
            maxWidth: isWeb ? 0.5 * windowWidth : windowWidth
        }
    });

    if (props.errorText != undefined && props.errorText.length > 1) {
        return (

            <View style={styles.container, dynamicErrorStyle.container}>
                <Text style={styles.text}>{props.errorText}</Text>
            </View>
        )
    } else {
        return(null)
    }
}