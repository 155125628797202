
import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import {isPortraitView} from '../../../utils/isPortraitView'

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {adjustFontSize} from '../../adjustFontSize'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height


const screenRatio = windowWidth / windowHeight

// const globalState = require('../../../assets/GlobalState')
var cardWidth;
var cardHeight;

const portraitView = isPortraitView()
if (portraitView) {
    // cardWidth = wp(47)
    cardWidth = wp(47)
    cardHeight = hp('45%')
} else {
    cardWidth = (screenRatio > 0.5) ? wp(0.58 * windowHeight / windowWidth * 45) : wp(45)
    cardHeight = hp('40%')
}

// const cardWidth = (screenRatio > 0.5) ? wp(0.55 * windowHeight / windowWidth * 45) : wp(45)


export var styles = StyleSheet.create({
    cardTouchableOpacity: {
        // backgroundColor: "#000",
		// flex: 1,
        borderRadius: windowHeight * 0.05,
        // marginHorizontal: ' 3%',
        // // marginVertical: 0,

        // width: wp('45%'),
        // width: wp('30%'),
        minWidth: cardWidth,
        maxWidth: cardWidth,
        minHeight: cardHeight,
        maxHeight: cardHeight,
        // maxWidth: wp('45%'),
        // backgroundColor: "#FFF",
        // flex: 1,
        // marginTop: 20,
        // marginHorizontal: windowWidth * 0.02,
        // marginHorizontal: windowWidth*0.005,
        marginHorizontal:  portraitView? windowWidth*0.01 :windowWidth*0.005,
        // marginHorizontal: 0,
        // marginBottom: windowHeight * 0.02,
        // marginBottom: 10,
        //needs space for shadow?
        shadowOffset: {
            width: 0,
            height: windowHeight * 0.002
            //scale these
        },
        // elevation: windowHeight * 0.005,
        elevation: windowHeight * 0.01,
        shadowRadius: windowHeight * 0.004,
        shadowOpacity: 0.75,
        // opacity: 0.1,
        marginBottom: windowHeight* 0.01,	
    },

    cardContainerStyle: {
		// borderRadius:  windowHeight * 0.05,
		// // marginHorizontal: 0,
        // // marginTop: 100,
        // marginVertical: 0,
		// // padding: 0,
		// // height: windowHeight * 0.1,
		// flex: 1,
		// // marginBottom: windowHeight * 0.09,
		// backgroundColor: '#000',
		// // marginBottom: windowHeight * 0.2,
        // width: '100%',
        // // height: '100%',
        // backgroundColor: '#000',
        // // opacity: 0.11,
        flex: 1,
        borderRadius: windowHeight * 0.05,
        // marginHorizontal: ' 3%',
        width: '100%',
        marginBottom: windowHeight * 0.012,
        // marginBottom: windowHeight * 0.1,
        marginVertical: 0,
	},
    cardView: {
        flex: 1,
        borderTopLeftRadius: windowHeight * 0.05,
		borderTopRightRadius: windowHeight * 0.05,
		// marginHorizontal: '3%',
        width: '100%',
        // marginVertical: 0,
        // backgroundColor: '#fff',
        // marginBottom: windowHeight * 0.002,
        // marginBottom: windowHeight * 0.1,
        // paddingBottom: windowHeight * 0.05
        // minHeight: windowHeight * 0.1,
        // opacity: 0.2,
	},
	cardImageStyle: {
		marginTop: 0,
		marginHorizontal: 0,
		padding: 0,
		flex: 1,
		borderTopLeftRadius:windowHeight * 0.05,
		borderTopRightRadius: windowHeight * 0.05,
		// height: windowHeight * 0.1,
		minHeight: cardHeight * 0.6,
        maxHeight: 10,
        // opacity: 0.5,
        overflow: 'hidden',
        width: '100%',
        // backgroundColor: '#000',
	},
	cardHeaderTextStyle: {
        color: '#FFF',
		// fontSize:  portraitView?hp(3.5) :hp(3),
        fontSize: portraitView ? hp(2.5) : hp(2.5),
        // fontWeight: portraitView ? '500' : '500',
        // fontWeight:'700',
		opacity: 0.87,
        marginLeft: windowHeight * 0.02,
        maxWidth: cardWidth *0.75,
        marginRight: windowWidth * 0.01,
        // backgroundColor: '#00F',
        
		// fontWeight: 'medium',
		// fontFamily: 'roboto',
	},
	cardDescriptionTextStyle: {
		// backgroundColor: '#AAA',
		color: '#FFF',
		fontSize:  portraitView?hp(2):hp(2),
		opacity: 0.6,
        marginHorizontal: windowHeight * 0.02,
        overflow: 'hidden',
        maxHeight: windowHeight * 0.10,
        minHeight: windowHeight * 0.1,
        // borderRadius: 1,
        // borderWidth: 0,
        // flexShrink: 1,
        // height: 'auto',
        // marginBottom: 
        flex: 1, 
        // backgroundColor: '#FFF'
        // marginBottom: windowHeight * 0.06,
        // paddingBottom: windowHeight * 0.05,
        
		// fontWeight:'medium',
    },
    subTitleTextContainer: {
        // backgroundColor: '#000',
        // opacity: 0.7,
        // maxHeight: windowHeight * 0.1
        paddingBottom: windowHeight * 0.1,
        marginBottom: windowHeight * 0.1,
        // paddingBottom: windowHeight * 0.2
    },
    titleRow: {
        flexDirection: 'row',
        justifyContent: 'space-between'

        // justifyContent: 'flex-start'
    },
    tieredAchievementImageStyle: {
        marginTop: 0,
		marginHorizontal: 0,
		padding: 0,
        flex: 1,
        // borderRadius: 2,
        // borderWidth: 2,
        // borderColor: '#000',
		// borderTopLeftRadius:windowHeight * 0.05,
		// borderTopRightRadius: windowHeight * 0.05,
		// height: windowHeight * 0.1,
		minHeight: cardHeight * 0.15,
        maxHeight: cardHeight * 0.15,
        maxWidth:cardWidth * 0.3,
        // opacity: 0.5,
        overflow: 'hidden',
        // width: '100%',
    }
});