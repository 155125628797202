import { View, ScrollView, Dimensions, StyleSheet } from 'react-native';
import React, { useEffect, useState } from "react";
import { Input } from 'react-native-elements';
import { CardButton } from '../components/common/CardButton';
import { images, dashboardImages } from '../assets/images';
import { getElement } from '../firebase.config';
import { styles, rewardsCardStyle, inputContainerStyle } from '../styles/Standard/screens/LearningCenterTopics'; //rename
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { primaryColour, primarySideColour, cardColours } from '../styles/globalColours';
import { useNavigation } from '@react-navigation/native';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import Constants from 'expo-constants';

import {EmptyPageBackground} from '../components/common/EmptyPageBackground'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const cardsContainerWidth = (windowWidth / windowHeight > 1) ? 0.5 * windowHeight * 1.2 : windowWidth

export function LearningCenterTopics({ navigation }) {
	const [groupedTopics, setGroupedTopics] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const [ searchText, setSearchText ] = useState("");
	
    const searchBarContainerStyle = StyleSheet.create({
        containerStyle: inputContainerStyle
    });

    const isWeb = (Constants.platform != 'android' && Constants.platform != 'ios' && windowWidth / windowHeight > 0.9);

	if (groupedTopics == undefined) {
		getAndSetTopics()
	}

	const cardBackgroundColours = ["#FF5454", "#549EFF", "#FF924A", "#FF5454", "#549EFF",];
	const cardShadowColours = ['#EB3C3C', '#5378FF', '#FF6500', '#EB3C3C', '#5378FF',];

	const achievementColours = [
		{
			shadowColour: cardShadowColours[0],
			backgroundColour: cardBackgroundColours[0],
		},
		{
			shadowColour: cardShadowColours[1],
			backgroundColour: cardBackgroundColours[1],
		},
		{
			shadowColour: cardShadowColours[2],
			backgroundColour: cardBackgroundColours[2],
		},
		{
			shadowColour: cardShadowColours[3],
			backgroundColour: cardBackgroundColours[3],
		},
		{
			shadowColour: cardShadowColours[4],
			backgroundColour: cardBackgroundColours[4],
		},
	]

	var isEmptyPage = false
	if (isLoading == false && (groupedTopics == undefined || groupedTopics.length < 1)) {
		isEmptyPage = true
	}

	function getAndSetTopics() {
		
		const globalState = require('../assets/GlobalState')
		if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined || globalState.DBPath.companyId.length < 1 || globalState.DBPath.departmentId.length < 1) {
			// alert('Company or Department not set in context.')
			navigation.replace('login')
			return
		}
		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'TrainingMaterial']
		getElement(path).get().then(function (docs) {
			if (docs != undefined && docs.docs != undefined) {
				var topics = []
				docs.docs.map(doc => {
					var newTopic = doc.data()
					newTopic['documentId'] = doc.id
					if (doc.data() != undefined && doc.data().disabled != true) {
						topics.push(newTopic)
					}
				})
				setGroupedTopics(assignColours(topics.sort((a, b) => a.order === b.order ? 0 : a.order > b.order ? 1 : -1)))
				setIsLoading(false)
			} else {
				console.log("Document not found");
			}
		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
	}

	//todo add icon/style for completion indication

	function assignColours(list) {
		for (var i = 0; i < list.length; i = i + 1) {
			if (list[i] != undefined) {
				const colourIndex = Math.floor(Math.random() * (achievementColours.length - 1))
				list[i]["backgroundColour"] = achievementColours[colourIndex].backgroundColour
				list[i]["shadowColour"] = achievementColours[colourIndex].shadowColour
			}
		}
		return list
	}

	function getDisplayTopics() {

		let topics = groupedTopics || [];
		
		return [ ...topics ].filter(t => t.name.trim().toLowerCase().includes(searchText.trim().toLowerCase()));

	}

	function renderTopics() {
		
        return getDisplayTopics().map((topic, i) =>
			<CardButton key={"a" + i}
				destination='learning_center_subtopics'
				collection={'trainingMaterialTopicId'}
				document={topic['documentId']}
				title={topic.name}
				description={topic.subtitle}
				image={(topic.imgUrl != undefined && topic.imgUrl.length > 0) ? { uri: topic.imgUrl } : images[Math.floor(Math.random() * (images.length - 1))]}
				shadowColour={topic.shadowColour}
				backgroundColour={topic.backgroundColour}
				customStyle={rewardsCardStyle} />
		)
    }

	return (
		<View style={styles.dashboardContainer}>
			<LoadingIndicator isLoading={isLoading}/>
			<View style={{ alignItems: "center", justifyContent: "center"}}>
				<Input
					placeholder='Search Topics'
					inputStyle={{ color: '#000', fontSize: hp(3), height: (isWeb) ? hp(6) : '100%', borderWidth: 0 }}
					onChangeText={(searchText) => setSearchText(searchText)}
					containerStyle={searchBarContainerStyle.containerStyle}
					inputContainerStyle={{ borderWidth: 0, marginTop: 0 }} />
			</View>
			<ScrollView style={styles.cardsContainer}>
				<View style={styles.wideContainer}>
					<View style={styles.cardsContainerContent}>
						{
							renderTopics()
						}
					</View>
				</View>
			</ScrollView>
			<EmptyPageBackground isEmpty={isEmptyPage}/>
		</View>
	);
}


