import React from "react";
import { Text, ScrollView, View, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { styles, cardStyle } from '../styles/Standard/screens/CompanySelection';
import { cardColours } from '../styles/globalColours';
import { logos } from '../assets/images';
import { CardButton } from '../components/common/CardButton';

export function CompanySelection({ route, navigation }, props) {

    const users = route?.params?.users;

    function selectCompany(user) {
        
        let companyId = user.company;
        let departmentId = user.department;
        let globalState = require('../assets/GlobalState');
             
        globalState.DBPath.companyId = companyId;
        globalState.companyIdForLoginImage = companyId;
        globalState.DBPath.departmentId = departmentId;
        globalState.DBPath.userId = user.userId || user.id;
        globalState.userProfile = user;
        globalState.userGameRetryOverride = user.gameRetryOverride;
        
        AsyncStorage.setItem('globalState', JSON.stringify(globalState));

        navigation.replace('dashboard');

    }

	function renderCompanies() {

		return (
			<View style={styles.cardsContainerContent}>
                {
                    users?.map((user, u) => (
                        <CardButton 
                            key={u}
                            destination='dashboard' 
                            title={user.companyName}
                            image={(user.companyLogo != undefined) ? { uri: user.companyLogo } : ""} 
                            shadowColour={cardColours[3].shadow} 
                            backgroundColour={cardColours[3].background} 
                            customStyle={cardStyle} 
                            onPressOverride={() => selectCompany(user)}
                        />
                    ))
                }
			</View>
		);
	}

    return (
		<ScrollView >
			<View style={styles.pageContainer}>
                
                <View style={styles.logoContainer}>
                    <Image
                        style={styles.logo}
                        activeOpacity={0.9}
                        resizeMode="contain"
                        source={logos.gameOn} />
                </View> 

				<Text style={styles.pageTitle}>Select Company</Text>
				<Text style={styles.pageDescription}>
                    This user account is linked to multiple companies. Please specify for which company you'd like to log in.
                </Text>

				<View style={styles.companyCardsContainer}>
					{renderCompanies()}
				</View>
                
			</View>
		</ScrollView>
    );
}