import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { widthPercentageToDP, heightPercentageToDP } from 'react-native-responsive-screen';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export var webStyles = StyleSheet.create({
	logo: {
		// color: '#000',
		// opacity: 0.87,
        // fontSize: heightPercentageToDP(8),
        // fontWeight: '700',
		marginHorizontal: windowWidth * 0.07,
		// marginTop: windowHeight * 0.03,
		flex: 1,
		flexShrink: 1,
		flexBasis: 0,
		maxHeight: windowHeight * 0.2,
        minHeight: windowHeight * 0.1,
        // maxWidth: windowWidth * 0.4,
		minWidth: windowWidth * 0.2,
        // backgroundColor: "#FF5454",
        // color: '#FFF',
        // borderRadius: windowHeight * 0.03,
        paddingHorizontal: heightPercentageToDP(2),
        paddingVertical:  heightPercentageToDP(1)
        // fontWeight: '800'
        // fontFamily: "Roboto-heavy"
		// justifyContent: "center"
		// marginBottom: windowHeight * 0.02,
		// overflow:'hidden'
    },
    logoContainer: {
        maxHeight: windowHeight * 0.3,
        minHeight: windowHeight * 0.3,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    dashboardContainer: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
    },
    loginButton: {
        maxHeight: windowHeight * 0.1,
    },
    buttonsContainerStyle: {
        // maxHeight: windowHeight * 0.2,
        minHeight: windowHeight * 0.2,
        // justifyContent: "center",
        alignItems: "center",
        flex:1
    },
    buttonContainerStyle: {
        maxHeight: windowHeight * 0.15,
        minHeight: windowHeight * 0.15,
        // minWidth: windowWidth * 0.6
        minWidth: widthPercentageToDP(20),
        // backgroundColor: '#000'
    },
    forgotPasswordText: {
        // fontSize: heightPercentageToDP(3),
        marginTop: heightPercentageToDP(1),
        color: '#DB3C6C'
    },
    inputContainers: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    emptyBackground: {
        minHeight: windowHeight,
        // height: '100%',
        // maxHeight: windowHeight,
        minWidth: windowWidth,
        maxWidth: windowWidth,
        width: windowWidth,
        position: 'absolute',
        // top: -1 * 300 * windowWidth/windowHeight, //only for FDH
        // top:  ( 0.64 - 0.61 )  * wind  owHeight, //only for FDH
        // top: -180, //only for FDH
        top: 0, //works for all mobile
        // resizeMode: 'center',
        // opacity: isPortraitView()?0:1,
        backgroundColor: '#000',
        // resizeMode: 'cover' // cover works perfect for web but not android
    },
    emptyScreenImage: {
        minWidth: windowHeight * 0.3,
        backgroundColor: '#FF0',
    }
});

export const webContainerStyle = {
    borderWidth: windowHeight * 0.003,
    borderColor: '#FF5454',
    borderRadius: windowHeight * 0.03,
    marginHorizontal: windowWidth * 0.05,
    overflow: 'hidden',
    // maxWidth: windowWidth * 0.85,
    maxWidth: widthPercentageToDP(20),
    marginVertical: heightPercentageToDP(1),
    minHeight: heightPercentageToDP(6),
    maxHeight: heightPercentageToDP(6),
    paddingHorizontal: 0,
    paddingHorizontal:0,
    // fontSize: heightPercentageToDP(3)
    // marginTop: 10
    // backgroundColor: '#000'
}

export const mobileContainerStyle = {
    borderWidth: windowHeight * 0.003,
    borderColor: '#FF5454',
    borderRadius: windowHeight * 0.03,
    marginHorizontal: windowWidth * 0.05,
    overflow: 'hidden',
    // maxWidth: windowWidth * 0.85,
    maxWidth: widthPercentageToDP(80),
    marginVertical: heightPercentageToDP(2),
    minHeight: heightPercentageToDP(8),
    maxHeight: heightPercentageToDP(8),
    // fontSize: heightPercentageToDP(3)
    // marginTop: 10
    // backgroundColor: '#000'
}

export const buttonColor = '#FF5488'
export const buttonShadowColor = '#DB3C6C'

export const loginButtonStyle = StyleSheet.create({
    button: {
        shadowColor: '#FF5653', 
        // maxHeight: 0.5
    }
});

export var mobileStyles = StyleSheet.create({
	logo: {
		marginHorizontal: windowWidth * 0.07,
		flex: 1,
		flexShrink: 1,
		flexBasis: 0,
        minHeight: windowHeight * 0.1,
		minWidth: windowWidth * 0.5,
        borderRadius: windowHeight * 0.03,
    },
    logoContainer: {
        marginTop: heightPercentageToDP(5),
        maxHeight: windowHeight * 0.30,
        minHeight: windowHeight * 0.30,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    dashboardContainer: {
		backgroundColor: "#FFF",
        // height: '100%',
        minHeight:windowHeight,
		width: '100%',
    },
    loginButton: {
        maxHeight: windowHeight * 0.1,
    },
    buttonsContainerStyle: {
        minHeight: windowHeight * 0.2,
        // justifyContent: "center",
        alignItems: "center",
        flexDirection:'column',
        flex: 1,
        // backgroundColor:'#00f'
    },
    buttonContainerStyle: {
        maxHeight: windowHeight * 0.15,
        minHeight: windowHeight * 0.15,
        minWidth: widthPercentageToDP(60),
        // backgroundColor:'#000'
    },
    forgotPasswordText: {
        fontSize: heightPercentageToDP(2.5),
        // marginTop: heightPercentageToDP(0.),
        marginTop: 0,
        paddingTop: heightPercentageToDP(1),
        paddingBottom: heightPercentageToDP(2),
        paddingHorizontal:heightPercentageToDP(7),
        // backgroundColor: '#000',
        color: '#DB3C6C'
    },
    inputContainers: {
        marginTop:  heightPercentageToDP(2.5),
        justifyContent: 'center',
        alignItems: 'center'
    }
});


export const webInputContainerStyle = {
    borderColor: '#FFF',
    borderWidth: 0,
    borderRadius: 0,
    overflow: 'hidden',
    minWidth: windowWidth * 0.1,
    marginVertical: 0,
    maxWidth:'100%',
    marginHorizontal: 0.01 * windowWidth,
    minHeight: heightPercentageToDP(6),
    maxHeight: heightPercentageToDP(6),
    backgroundColor: '#FFF'
}

export const mobileInputContainerStyle = {
    borderWidth: 0,
    borderColor: '#FFF',
    borderRadius: 0,
    marginHorizontal: windowWidth * 0.05,
    overflow: 'hidden',
    // maxWidth: windowWidth * 0.85,
    // maxWidth: widthPercentageToDP(20),
    marginVertical: heightPercentageToDP(1),
    minHeight: heightPercentageToDP(6),
    maxHeight: heightPercentageToDP(6),
    // fontSize: heightPercentageToDP(3)
    // marginTop: 10
    // backgroundColor: '#00adsasda0'
}