import { ScrollView, View, Dimensions, Alert } from 'react-native';
import React, { useState } from "react";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { ListItem } from 'react-native-elements';

import { styles } from '../styles/Standard/screens/LearningSubTopics.styles'; //rename
import { StyledSubTitleButton } from '../components/common/StyledSubTitleButton';
import { primaryColour, primarySideColour } from '../styles/globalColours';
import { getElement } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { checkProfileFields } from '../utils/checkProfileFields'
import {EmptyPageBackground} from '../components/common/EmptyPageBackground'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export function LearningSubTopics({ navigation }, props) {
	var globalState = require('../assets/GlobalState')

	const isTesting = false
	if (isTesting) {
			globalState.DBPath.companyId = 'aCompanyName'
		globalState.DBPath.departmentId = 'FinanceDepartment'
		globalState.DBPath.trainingMaterialTopicId = 'KIAPicanto'
	}
	
	navigation.setOptions({ title: globalState.currentScreenTitle })
	const [isLoading, setIsLoading] = useState(true)

	const [subTopicList, setSubTopicList] = useState([])
	const [topicInfo, setTopicInfo] = useState([])
	const [areasCovered, setAreasCovered] = useState([])
	const [triggerRefresh, setTriggerRefresh] = useState(false)
	const [examGameId, setExamGameId] = useState(undefined)

	const [numberOfFetchTries, setNumberOfFetchTries] = useState(0)

	// make sure errors wont cause frequest re-requiesting

	if ( (subTopicList == undefined || subTopicList.length == 0) && numberOfFetchTries < 2) { // do request seaprately?
		getAndSetTopic()
		getAndSetSubTopics()
		setNumberOfFetchTries(numberOfFetchTries+1)
	}
	
	var isEmptyPage = false
	if (isLoading == false && (subTopicList == undefined || subTopicList.length < 1)) {
		isEmptyPage = true
	}


	function getAndSetSubTopics() {
		if (globalState.DBPath.companyId != undefined && globalState.DBPath.companyId.length> 0 &&  globalState.DBPath.departmentId != undefined &&  globalState.DBPath.departmentId.length>0 && globalState.DBPath.trainingMaterialTopicId != undefined && globalState.DBPath.trainingMaterialTopicId.length > 0) {
			var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'TrainingMaterial', globalState.DBPath.trainingMaterialTopicId, "SubTopics"]
			getElement(path).get().then(function (docs) {
				if (docs != undefined && docs.docs != undefined) {
					var newSubTopicsList = []
					docs.docs.map(doc => {
						var newSubTopic = doc.data()
						newSubTopic['documentId'] = doc.id
						if (globalState.userProfile != undefined && globalState.userProfile.learningCentreProgress != undefined &&
							globalState.userProfile.learningCentreProgress.subTopicsRead != undefined &&
							globalState.userProfile.learningCentreProgress.subTopicsRead.indexOf(globalState.DBPath.trainingMaterialTopicId + "_" + newSubTopic.subTitle) > -1) {
							newSubTopic.icon = 'done'
						} else {
							newSubTopic.icon = 'check-box-outline-blank'
						}
						newSubTopicsList.push(newSubTopic)
					})
					setSubTopicList(newSubTopicsList.sort((a, b) => a.order === b.order ? 0 : a.order > b.order ? 1: -1))
					setIsLoading(false)
				} else {
					console.log("Document not found");
				}
			}).catch(function (error) {
				console.log("Error getting doc", error);
			});
		} else {
			Alert.alert("Context information not set.")
		}
	}

	function getAndSetTopic() {

		if (globalState.DBPath.companyId != undefined && globalState.DBPath.departmentId != undefined && globalState.DBPath.trainingMaterialTopicId != undefined) {

			var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'TrainingMaterial', globalState.DBPath.trainingMaterialTopicId]
			getElement(path).get().then(function (doc) {
				if (doc != undefined) {
					const topicInfoObject = doc.data()
					// const subTopicsObject = topicInfoObject['subTopics']
					// var topicArray = []
					// Object.keys(subTopicsObject).map(function (key, i) {
					// 	var newSubTopic = subTopicsObject[key]

					// 	if (globalState.userProfile != undefined && globalState.userProfile.learningCentreProgress != undefined &&
					// 		globalState.userProfile.learningCentreProgress.subTopicsRead != undefined &&
					// 		globalState.userProfile.learningCentreProgress.subTopicsRead.indexOf(globalState.DBPath.trainingMaterialTopicId + "_" + newSubTopic.subTitle) > -1) {
					// 		newSubTopic.icon = 'done'
					// 	} else {
					// 		newSubTopic.icon = 'check-box-outline-blank'
					// 	}
					// 	topicArray.push(newSubTopic)
					// })
					// setSubTopicList(topicArray)
					setAreasCovered(topicInfoObject['areasCovered'])
					setExamGameId(topicInfoObject.gameExamId)
					// setIsLoading(false)
				} else {
					console.log("Document not found");
				}
			}).catch(function (error) {
				console.log("Error getting doc", error);
			});
		} else {
			console.log('An important property for the firestore path is not set in globalState.DBPath.')
			console.log(globalState.DBPath.companyId + " " + globalState.DBPath.departmentId + " " + globalState.DBPath.trainingMaterialTopicId)
			// navigation.goBack()
		}
	}

	// not sure about scope of gameId
	function navigateExamAndSaveRead() {
		// updateprofile
		var globalState = require('../assets/GlobalState');

		if (examGameId != undefined && examGameId.length > 0) {
			const gameId =examGameId
			var newUserProfile = globalState.userProfile
			if (newUserProfile != undefined) {
				if (newUserProfile.learningCentreProgress == undefined) {
					newUserProfile.learningCentreProgress = {}
				}
				if (newUserProfile.learningCentreProgress.examsTaken == undefined) {
					newUserProfile.learningCentreProgress.examsTaken = []
				}
				if (newUserProfile.learningCentreProgress.examsTaken.indexOf(gameId) < 0) {
					newUserProfile.learningCentreProgress.examsTaken.push(gameId)
				}
				if (checkProfileFields(newUserProfile)) {
					globalState.userProfile = newUserProfile
					var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
					getElement(path).set(newUserProfile)
				} else {
					// Alert.alert("Profile fields not valid. Not Updating profile.")
				}
				
				setTriggerRefresh(!triggerRefresh)
				// testGameId
				//  set specific game details (dbpath)
			} else {
				// Alert.alert("User profile is not set.")
				console.log("Userprofile is not set")
			}
			// navigation.navigate('game')
		} else {
			// Alert.alert("Game ID not defined. Contact manager.")
			console.log("Game ID not defined. Contact manager.")
			
		}
		// globalState.DBPath.gameCategoryId = 'NPLFOv2WDD98lXW51zVJ'
		if (examGameId != undefined &&examGameId.length > 0) {
			// globalState.DBPath.gameId = 'ZA3736x4p9tmmrEjd1lC'
			globalState.DBPath.gameId = examGameId
			navigation.navigate('game_categories')
		} else {
			Alert.alert("The exam is not set")
		}
		
	}

	function renderExamButton(gameId) {
		var globalState = require('../assets/GlobalState')
		var isExamTaken = false
		if (gameId != undefined && globalState.userProfile != undefined && globalState.userProfile.learningCentreProgress != undefined &&
			globalState.userProfile.learningCentreProgress.examsTaken != undefined &&
			globalState.userProfile.learningCentreProgress.examsTaken.indexOf(gameId) > -1) {
			isExamTaken = true
		}
		if (isLoading == false && examGameId != undefined && examGameId != null) {
			return (
				<StyledSubTitleButton onPressFn={navigateExamAndSaveRead} isExamTaken={isExamTaken} disabled={false} text='Take final exam' description="Prove you know your stuff"
					destination={'game_categories'} backgroundColor={primaryColour} shadowColour={primarySideColour}></StyledSubTitleButton>
			)
		}
	}

	function renderSubTopicList() {
		// setHasIconChanged(false)
		const globalState = require('../assets/GlobalState')
		return (subTopicList.map((subTopic, i) => (
			<ListItem
				key={i}
				// leftAvatar={{ source: { uri: subTopic.imageUrl }, size:hp(5) }}
				leftIcon={{ name: subTopic.icon, color: primaryColour, size: hp(4) }}
				// leftIcon={{ name: 'check', color: primaryColour, size: hp(4) }}   
				contentContainerStyle={{ marginLeft: 0.03 * windowWidth }}
				// containerStyle={{ width: globalState.portraitView ? windowWidth :0.5* windowHeight * 1.0, maxHeight: windowHeight * 0.15, marginVertical: 0 }}
				containerStyle={{ width: globalState.portraitView ? windowWidth : '100%', maxHeight: windowHeight * 0.15, marginVertical: 0 }}
				title={subTopic.title}
				titleStyle={{ fontSize: hp(3) }}
				subtitle={subTopic.subTitle}
				subtitleStyle={{ fontSize: hp(2) }}
				badge={{ value: subTopic.timeToLearn + " min", textStyle: { color: '#000', opacity: 0.6, fontWeight: '500', fontSize: hp(2) }, badgeStyle: { backgroundColor: '#FFF' }, containerStyle: { marginTop: 0 } }}
				onPress={() => {
					var globalState = require('../assets/GlobalState');
					if (subTopic.title) {
						var newScreenTitle = subTopic.title;
						globalState.currentScreenTitle = newScreenTitle;
					}
					subTopic.icon = 'done'
					setTriggerRefresh(!triggerRefresh)
					var newUserProfile = globalState.userProfile
					if (newUserProfile != undefined) {
						if (newUserProfile.learningCentreProgress == undefined) {
							newUserProfile.learningCentreProgress = {}
						}
						if (newUserProfile.learningCentreProgress.subTopicsRead == undefined) {
							newUserProfile.learningCentreProgress.subTopicsRead = []
						}
						
						newUserProfile.learningCentreProgress.subTopicsRead.push(globalState.DBPath.trainingMaterialTopicId + "_" + subTopic.subTitle)
						if (checkProfileFields(newUserProfile)) {
							globalState.userProfile = newUserProfile
							var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
							getElement(path).set(newUserProfile)
						} else {
							// Alert.alert("Profile fields not valid. Not Updating profile.")
						}
					} else {
						console.log("Profile not defined")
					}

					navigation.navigate('learning_center_material', { subTopic: subTopic })
				}
				}
			/>
		)))
	}

	return (
		<View>
			<ScrollView style={styles.background}>
			<EmptyPageBackground isEmpty={isEmptyPage}/>
				<View style={styles.wideContainer}>
					<View style={styles.cardsContainerContent}>
						<View style={{ marginVertical: 0, width: '100%' }}>{renderSubTopicList()}</View>
						{renderExamButton((examGameId != undefined) ? examGameId : undefined)}
					</View>
				</View>
			</ScrollView>
			<LoadingIndicator isLoading={isLoading} />
		</View>
	);
}