import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? 0.7 * windowHeight * 1.2 : windowWidth


export var styles = StyleSheet.create({
	list: {

	},
	background: {
		height: '100%',
		backgroundColor: '#FFF',
		// width: '100%',
		// minHeight: windowHeight * 0.8,
	},
	wideContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#000',
		// minHeight: windowHeight,
		justifyContent: 'flex-start',
		// alignItems: 'space-between',
		flexDirection: 'column',
		flexWrap: 'wrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
	},
	textAlreadyPlayed: {
		color: '#000',
		opacity: 0.87,
		// fontSize: adjustFontSize(16),
		fontSize: hp('2.50%'),
		marginHorizontal: windowWidth * 0.07,
		flex: 0.5,
		flexShrink: 1,
		flexBasis: 0,
		maxHeight: windowHeight * 0.1,
		minHeight: windowHeight * 0.1,
		textAlign: "center",
		textAlignVertical: "center",
		// backgroundColor: "#000",
		// justifyContent: "center"
		marginBottom: windowHeight * 0.02,
		overflow: 'hidden'
	}
});

// use start en end radius for the smooth

