import { StyleSheet, Text, View, Button, Image, Dimensions, ScrollView, Alert,SafeAreaView } from 'react-native';
import React, { useState, useEffect } from "react";
import { ListItem } from 'react-native-elements';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

import { styles } from '../styles/Standard/screens/Leaderboard.styles'; //rename
import { getElement, getStorageRef } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { images } from '../assets/images';

import { EmptyPageBackground } from '../components/common/EmptyPageBackground'
import GameState from '../assets/GameState';

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const Tab = createMaterialTopTabNavigator();

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? windowHeight * 1.2 : windowWidth

export function Leaderboard({ navigation, route }) {

	const isGameLeaderboard = route.params?.isGameLeaderboard;

	const [list, setList] = useState(undefined)
	const [listImageURLs, setListImageURLs] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [hasImagesLoaded, setHasImagesLoaded] = useState(false)
	const [triggerRefresh, setTriggerRefresh] = useState(true)

	const [requestCount, setRequestCount] = useState(0)
	const [hasRequestedLeaderboard, setHasRequestedLeaderboard] = useState(false)

	const globalState = require('../assets/GlobalState')
	const gameState = require('../assets/GameState')
	const currentUserId = (globalState.DBPath.userId != null && globalState.DBPath.userId.length > 0) ? globalState.DBPath.userId : ""

	const [executedOnce, setExecutedOnce] = useState(false)

	var isEmptyPage = false
	if (isLoading == false && (list == undefined || list.length < 1)) {
		isEmptyPage = true
	}

	if (isLoading == true && requestCount > 9) {
		setIsLoading(false)
	}

	function getIsFinalLeaderboard() {
		return gameState.isLeaderboardUpdated
	}

	const maxRequestCount = 10

	var isFinalLeaderboard = gameState.isLeaderboardUpdated //ensure deep copy

	if (isGameLeaderboard) {
		if (list == undefined) {
			if (gameState.localGameLeaderboard != undefined) {

				gameState.localGameLeaderboard.sort((a, b) => { return b.score - a.score; });

				setList(gameState.localGameLeaderboard)
			} else {
				setList([])
				// set
			}
			setIsLoading(false)
		}
	} else {
		if (((isFinalLeaderboard == false && requestCount < maxRequestCount) || list == undefined) && hasRequestedLeaderboard == false) {
			if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined || globalState.DBPath.companyId == "" || globalState.DBPath.departmentId == "") {
				Alert.alert("Company or  department not set");
				navigation.replace('login')
				// logout?
				return null
			}

			if (isLoading == false) {
				setIsLoading(true)
			}
			setList([])
			var newList = []
			var path = (isGameLeaderboard != undefined && isGameLeaderboard == true) ?
				['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', globalState.DBPath.gameId] :
				['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', 'Leaderboard']

			getElement(path).get().then(function (doc) {
				if (doc.exists) {
					const preProcessedLeaderboard = doc.data()['users']
					var leaderboardKeys = Object.keys(preProcessedLeaderboard)
					leaderboardKeys.sort()
					for (var i = 0; i < leaderboardKeys.length; i++) {
						var leaderboardEntry = preProcessedLeaderboard[leaderboardKeys[i]]
						newList.push(leaderboardEntry)
					}

					newList.sort((a, b) => { return b.score - a.score; });

					setList(newList)

				} else {
					console.log("Document not found");
				}

				if (getIsFinalLeaderboard() == false) {
					setHasRequestedLeaderboard(false)
					setRequestCount(requestCount + 1)
				} else {
					setIsLoading(false)
				}
				// setHasRequestedLeaderboard(true)
			}).catch(function (error) {
				if (requestCount + 1 > maxRequestCount) {
					setIsLoading(false)
				} else {
					setHasRequestedLeaderboard(false)
					setRequestCount(requestCount + 1)
				}
			});
			setHasRequestedLeaderboard(true)
			if (isFinalLeaderboard == true) {
				setIsLoading(false)
			}
		}
	}

	if (!hasImagesLoaded) {
		loadImages()
	}

	// limit # of tries
	async function loadImages() {
		if (list != null && list.length > 0) {
			for (var i = 0; i < list.length; i++) {
				loadImage(i)
			}
			setHasImagesLoaded(true)
		}
	}

	async function loadImage(i) { 
		if (list[i].imageRef != undefined && list[i].imageRef.length > 0) {
			try {
				var storageRef = getStorageRef("/user_profile_images/" + list[i].imageRef)
				var imageUrl = await storageRef.getDownloadURL().then(function (url) {
					var leaderboardEntry = list[i]
					leaderboardEntry.imageUrl = url
					var newList = list
					newList[i] = leaderboardEntry
					setList(newList.concat(leaderboardEntry))
					setListImageURLs()
					setTriggerRefresh(!triggerRefresh)
				});		
			} catch (e) {
				console.log(e)
			}
		}

	}

    function CapPointsToMax(points, maxPoints) {
        if (points > maxPoints) {
            return maxPoints;
        } else {
            return points;
        }
    }

    function CapScoreToMax(score) {
		if (score > 100) {
			return 100;
		} else {
			return score;
		}
    }

	function renderList() {

		if (list != undefined && list.length > 0) {
			
			return (
				<ScrollView style={styles.background}>
					<SafeAreaView>
					{list.map((l, i) => (
						<View style={styles.wideContainer}>
							<View style={styles.cardsContainerContent}>
								<ListItem
									key={i}
									leftAvatar={{ source: (l.imageUrl != undefined && l.imageUrl.length > 0) ? { uri: l.imageUrl } : images[0], size: heightPercentageToDP(6) }}
									contentContainerStyle={{ marginLeft: 0.03 * windowWidth }}
									title={l.name}
									titleStyle={{ fontSize: heightPercentageToDP(2.3), fontWeight: (l.userId == currentUserId) ? '600' : '400' }}
									badge={{ 
										value: CapScoreToMax(l.score) + "%", 
										textStyle: { 
											color: (l.score >= 50) ? '#00780C' : '#FF0000', 
											opacity: 0.87, 
											fontWeight: (l.userId == currentUserId) ? '700' : '500', 
											fontSize: heightPercentageToDP(2.5) }, 
											badgeStyle: { backgroundColor: '#FFF' }, 
											containerStyle: { marginTop: 0 } 
										}}
									containerStyle={{ minWidth: cardsContainerWidth * 0.5, marginBottom: 0, maxHeight: windowHeight * 0.1 }}
									bottomDivider
								/>
							</View>
						</View>
					))}
					</SafeAreaView>
				</ScrollView>
			);

		} else {
			return null
		}

	}

	function renderMobileList() {
		if (list != undefined && list.length > 0) {

			return (
				<ScrollView >
				<SafeAreaView style={styles.background}>
					{(list.map((l, i) => (
						<View style={styles.cardsContainerContent}>
							<ListItem
								key={i}
								leftAvatar={{ source: (l.imageUrl != undefined) ? { uri: l.imageUrl } : images[Math.floor(Math.random() * (images.length - 1))], size: heightPercentageToDP(6) }}
								contentContainerStyle={{ marginLeft: 0.03 * windowWidth, height: '100%' }}
								title={l.name} // test this by setting another poperty, like title in the url callback
								titleStyle={{ fontSize: heightPercentageToDP(3), fontWeight: (l.userId == currentUserId) ? 'bold' : '500' }}
								badge={{
									value: CapScoreToMax(l.score) + "%", 
									textStyle: {
										color: (l.score >= 50) ? '#00780C' : '#FF0000', opacity: 0.87, fontWeight: (l.userId == currentUserId) ? '600' : '500',
										fontSize: heightPercentageToDP(3)
									}, 
									badgeStyle: { 
										backgroundColor: '#FFF', 
										padding: 0, 
										margin: 0, 
										minHeight: heightPercentageToDP(8) 
									}, 
									containerStyle: { 
										marginTop: 0, 
										marginRight: windowWidth * 0.02 
									}
								}}
								containerStyle={{ minWidth: windowWidth, marginBottom: 0, maxHeight: windowHeight * 0.1, backgroundColor: (l.userId == currentUserId) ? '#FFF' : '#FFF' }}
								bottomDivider
							/>
						</View>
					)))}
					</SafeAreaView>
				</ScrollView>
			);

		} else {
			return null
		}
	}

	function renderMobilePointsList() {

		if (list != undefined && list.length > 0) {

			return (
				<ScrollView>
				<SafeAreaView>
					{list.map((l, i) => (
						<View style={styles.wideContainer}>
							<View style={styles.cardsContainerContent}>
								<ListItem
									key={i}
									leftAvatar={{ source: (l.imageUrl != undefined) ? { uri: l.imageUrl } : images[Math.floor(Math.random() * (images.length - 1))], size: heightPercentageToDP(6) }}
									contentContainerStyle={{ marginLeft: 0.03 * windowWidth, height: '100%' }}
									title={l.name} // test this by setting another poperty, like title in the url callback
									titleStyle={{ fontSize: heightPercentageToDP(3), fontWeight: (l.userId == currentUserId) ? 'bold' : '500' }}
									badge={{
										value: ((!l.points && l.points != 0) || (!l.maximumScore && l.maximumScore != 0) ? "Not Available" : CapPointsToMax(l.points, l.maximumScore) + " / " + l.maximumScore),
										textStyle: {
											color: ((!l.points && l.points != 0) || (!l.maximumScore && l.maximumScore != 0) || l.points < (l.maximumScore / 2)) ? '#FF0000' : '#00780C', opacity: 0.87, fontWeight: (l.userId == currentUserId) ? '600' : '500',
											fontSize: heightPercentageToDP(3)
										}, 
										badgeStyle: { backgroundColor: '#FFF', padding: 0, margin: 0, minHeight: heightPercentageToDP(8) }, 
										containerStyle: { marginTop: 0, marginRight: windowWidth * 0.02 }
									}}
									containerStyle={{ minWidth: windowWidth, marginBottom: 0, maxHeight: windowHeight * 0.1, backgroundColor: (l.userId == currentUserId) ? '#FFF' : '#FFF' }}
									bottomDivider
								/>
							</View>
						</View>
					))}
					</SafeAreaView>
				</ScrollView>
			);

		} else {

			return null;

		}

	}

	function renderPointsList() {

		if (list != undefined && list.length > 0) {

			return (
				<ScrollView style={styles.background}>
					<SafeAreaView>
					{list.map((l, i) => (
						<View style={styles.wideContainer}>
							<View style={styles.cardsContainerContent}>
								<ListItem
									key={i}
									leftAvatar={{ source: (l.imageUrl != undefined && l.imageUrl.length > 0) ? { uri: l.imageUrl } : images[0], size: heightPercentageToDP(6) }}
									contentContainerStyle={{ marginLeft: 0.03 * windowWidth }}
									title={l.name}
									titleStyle={{ fontSize: heightPercentageToDP(2.3), fontWeight: (l.userId == currentUserId) ? '600' : '400' }}
									badge={{ 
										value: ((!l.points && l.points != 0) || (!l.maximumScore && l.maximumScore != 0) ? "Not Available" : CapPointsToMax(l.points, l.maximumScore) + " / " + l.maximumScore),
										textStyle: { color: ((!l.points && l.points != 0) || (!l.maximumScore && l.maximumScore != 0) || l.points < (l.maximumScore / 2)) ? '#FF0000' : '#00780C', opacity: 0.87, fontWeight: (l.userId == currentUserId) ? '700' : '500', fontSize: heightPercentageToDP(2.5) }, 
										badgeStyle: { backgroundColor: '#FFF' }, 
										containerStyle: { marginTop: 0 } }}
									containerStyle={{ minWidth: cardsContainerWidth * 0.5, marginBottom: 0, maxHeight: windowHeight * 0.1 }}
									bottomDivider
								/>
							</View>
						</View>
					))}
					</SafeAreaView>
				</ScrollView>
			);

		} else {

			return null;

		}

	}

	return (
		<View>
			{isGameLeaderboard ?
				(
					globalState.portraitView ? renderMobileList() : renderList()
				)
				:
				(
					<View style={styles.background}>
						<Tab.Navigator>
							<Tab.Screen name="leaderboard" component={globalState.portraitView ? renderMobileList : renderList} options={{ title: "Total Scores" }} />
							<Tab.Screen name="total_scores" component={globalState.portraitView ? renderMobilePointsList : renderPointsList} options={{ title: 'Total Points' }} />
						</Tab.Navigator>
					</View>
				)
			}
			<LoadingIndicator isLoading={isLoading} />
			<EmptyPageBackground isEmpty={isEmptyPage} />
		</View>
	);
}



