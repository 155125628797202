import { View, ScrollView, Dimensions } from 'react-native';
import React, { useState } from "react";
import { ListItem } from 'react-native-elements';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { heightPercentageToDP } from 'react-native-responsive-screen';
import { styles } from '../styles/Standard/screens/AfterGame.styles';
import { images } from '../assets/images';
import { Leaderboard } from './Leaderboard';
import { playSound } from '../utils/playSound';
import { categoryWinSound } from '../assets/sounds'
import { useNavigation } from '@react-navigation/native';
import { isPortraitView } from '../utils/isPortraitView'
import { primaryColour } from '../styles/globalColours';
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width
const Tab = createMaterialTopTabNavigator();
const globalState = require('../assets/GlobalState');
const gameState = require('../assets/GameState');
export function AfterGame({ route, navigation }, props) {
    const [playedSound, setPlayedSound] = useState(false)
    globalState.isGameLeaderboard = true
    if (playedSound === false) {
        playSound(categoryWinSound)
        setPlayedSound(true)
    }
    function CapPointsToMax(points, maxPoints) {
        if (points > maxPoints) {
            return maxPoints;
        } else {
            return points;
        }
    }
    function myAnswers() {
        let list = [];
        if (gameState !== undefined && gameState.state.afterGameInfo !== undefined) {
            list = gameState.state.afterGameInfo
        }
        if (list === undefined) {
            return null
        }
        function renderWebAnswers() {
            const navigation = useNavigation();
            return (list.map((l, i) => (
                <ListItem
                    key={i}
                    title={'Q: ' + l.questionText}
                    titleStyle={{ fontSize: heightPercentageToDP(3), marginLeft:0 }}
                    subtitle={'My Answer: ' + l.answerText}
                    subtitleStyle={{ fontSize: heightPercentageToDP(2) }}
                    contentContainerStyle={{ marginLeft: 0.01 * windowWidth, width: '100%' }}
                    rightIcon={{ name: (l.isCorrect) ? 'check' : 'close',  color: (l.isCorrect === 1) ? '#00AD1F' : '#FF0000', size: heightPercentageToDP(5), iconStyle: { opacity: 0.87 } }}
                    containerStyle={{ width: '100%' }}
                    leftIcon={{
                        name: 'info', size: heightPercentageToDP(5), color:primaryColour,
                        iconStyle: { opacity: 0.87, marginRight:0},
                        style:{marginRight:0 },
                        containerStyle:{ marginRight:0},
                        onPress:
                            () => {
                                if (l.whereToFindAnswer !== undefined &&
                                    l.whereToFindAnswer.learningTopicId !== undefined && l.whereToFindAnswer.learningTopicId.length > 0 &&
                                    l.whereToFindAnswer.learningSubTopicId !== undefined && l.whereToFindAnswer.learningSubTopicId.length > 0 &&
                                    l.whereToFindAnswer.whereToFind !== undefined && l.whereToFindAnswer.whereToFind.length > 0
                                ) {
                                    globalState.DBPath.trainingMaterialTopicId = l.whereToFindAnswer.learningTopicId
                                    globalState.DBPath.learningSubTopicId = l.whereToFindAnswer.learningSubTopicId
                                    globalState.whereToFindAnswer = (l.whereToFindAnswer.whereToFind.length > 0) ?
                                        l.whereToFindAnswer.whereToFind : ""
                                    navigation.navigate('learning_center_material')
                                }
                            }
                    }}
                    bottomDivider
                />
            )))
        }
        return (
            <ScrollView style={styles.background}>
                <View style={styles.wideContainer}>
                    <View style={styles.cardsContainerContent}>
                        {renderWebAnswers()}
                    </View>
                </View>
            </ScrollView>
        );
    }
    function totalScores(){
        const currentUserId = (globalState.DBPath.userId != null && globalState.DBPath.userId.length > 0) ? globalState.DBPath.userId : ""
        let list = [];
        if (gameState !== undefined && gameState.localGameLeaderboard !== undefined) {
            gameState.localGameLeaderboard.sort((a, b) => b.points - a.points);
            list = gameState.localGameLeaderboard;
        }
        if (list === undefined) {
            return null;
        }
        function renderTotalScores() {
            const cardsContainerWidth = (!isPortraitView()) ? 0.7 * windowHeight * 1.2 : windowWidth
            return (list.map((l, i) => (
                <ListItem
                    key={i}
                    leftAvatar={{ source: (l.imageUrl !== undefined && l.imageUrl.length > 0) ? { uri: l.imageUrl } : images[0], size: heightPercentageToDP(6) }}
                    contentContainerStyle={{ marginLeft: 0.03 * windowWidth }}
                    title={l.name}
                    titleStyle={{ fontSize: heightPercentageToDP(2.3), fontWeight: (l.userId === currentUserId) ? '600' : '400' }}
                    badge={{
                        value: Math.round(CapPointsToMax(l.points, (l.maximumCorrect || l.maximumScore))) + " / " + (l.maximumCorrect || l.maximumScore),
                        textStyle: { color: (l.points > ((l.maximumCorrect || l.maximumScore) / 2)) ? '#00780C' : '#FF0000',
                        opacity: 0.87,
                        fontWeight: (l.userId === currentUserId) ? '700' : '500',
                        fontSize: heightPercentageToDP(2.5) },
                        badgeStyle: { backgroundColor: '#FFF' },
                        containerStyle: { marginTop: 0 } }}
                    containerStyle={{ minWidth: cardsContainerWidth * 1, marginBottom: 0, maxHeight: windowHeight }}
                    bottomDivider
                />
            )))
        }
        return (
            <ScrollView style={styles.background}>
                <View style={styles.wideContainer}>
                    <View style={styles.cardsContainerContent}>
                        {renderTotalScores()}
                    </View>
                </View>
            </ScrollView>
        );
    }
    return (
        <View style={styles.background}>
            <Tab.Navigator initialRouteName='leaderboard'>
                <Tab.Screen name="leaderboard" component={Leaderboard} options={{ title: `${gameState.gameInfo.name} Scoreboard` }} initialParams={{ isGameLeaderboard: true }} />
                <Tab.Screen name="total_scores" component={totalScores} options={{ title: 'Total Scores' }} />
                <Tab.Screen name="my_answers" component={myAnswers} passedData={props.passedData} options={{ title: 'My Answers' }} />
            </Tab.Navigator>
        </View>
    );
}
