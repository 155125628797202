
import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
// import { widthPercentageToDP, heightPercentageToDP } from 'react-native-responsive-screen'

import { primaryColour } from '../../globalColours'

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {isPortraitView} from '../../../utils/isPortraitView'

const portraitView = isPortraitView()
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

//make colour setting in style sheet a props parameter and put in main function

// if more flex help needed look at: https://stackoverflow.com/questions/43143258/flex-vs-flexgrow-vs-flexshrink-vs-flexbasis-in-react-native/43147710#43147710
export const styles = StyleSheet.create({
    text: {
        // marginRight: windowWidth * 0.06,
        paddingHorizontal: windowWidth* 0.08,
        // top: 100,
        // minHeight: 0.1,
        // maxHeight: 0.1,
        // height: 100,
        // left: windowWidth * 0.45
        color: '#F00',
        opacity: 0.86,
        marginBottom: windowHeight * 0.02,
        // backgroundColor: '#000',
        fontSize: hp(2.5)
    },
    container: {
        // position: 'absolute',
        // bottom: windowHeight * 0.1,
        // top: windowHeight* 0.8,
        // left: windowWidth * 0.37,
        // marginVertical:100,
        flex: 1,
        marginTop:windowHeight * 0.01,
        minWidth: portraitView ? windowWidth * 0.8 : hp(30),
        maxWidth: portraitView?windowWidth * 0.8:hp(30),
        minHeight:portraitView?windowHeight * 0.1: hp(10),
        borderRadius: hp(3),
        // backgroundColor: '#F00',
        // borderColor: '#F00',
        // borderWidth: 1,
        // opacity: 0.8,
        // top: 400,
        justifyContent: "center",
        alignItems: 'center',
        // backgroundColor: primaryColour
        // width: windowWidth * 0.225,
        // width: windowWidth * 0.225,
        // minHeight:  0.1 * windowHeight,
        //    maxHeight: 0.1 * windowHeight,

    }
});