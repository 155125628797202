
import { StyleSheet, Text, View, Image } from 'react-native';
import React, { useState } from "react";
import { useNavigation } from '@react-navigation/native';

import { styles } from '../../styles/Standard/common/CardButton.style';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Dimensions } from 'react-native';
import { Icon } from 'react-native-elements'

import { trophies } from '../../assets/images'

const windowWidth = Dimensions.get('window').width

export function CardButton(props) {

    const navigation = useNavigation();
    const [iconImage, setIconImage] = useState(undefined)
    const destination = (props.destination != undefined) ? props.destination : '';
    var disabled = false;
    const isReward = (props.isReward == true) ? true : false

    let customShadowStyle = StyleSheet.create({
        cardTouchableOpacity: {
            backgroundColor: props.shadowColour,
            opacity: (!props.isTransparent) ? 1 : 0.4,
        }
    });

    if (props.highlight) {
        customShadowStyle = StyleSheet.create({
            cardTouchableOpacity: {
                backgroundColor: props.shadowColour,
                opacity: (!props.isTransparent) ? 1 : 0.4,
                shadowColor: '#000',
                shadowOffset: { width: 3, height: 4 },
                shadowOpacity: 0.8,
                shadowRadius: 2, 
                elevation: 1,
            }
        });
    }

    const cardContainerStyle = StyleSheet.create({
        cardContainerStyle: {
            backgroundColor: props.backgroundColour,
        }
    });

    if (destination === '') {
        disabled = true;
    }

    if (iconImage == undefined && props.tier != undefined) {
        if (props.tier == 0) {
            setIconImage(trophies.gold)

        } else if (props.tier == 1) {
            setIconImage(trophies.silver)

        } else if (props.tier == 2) {
            setIconImage(trophies.bronze)

        } else if (props.tier == -1) {
            setIconImage(trophies.grey)
        }
    }

    //need to move onPress logic outside to make more general, and add  onpress parameter

    return (
        <TouchableOpacity onPress={() => {

            if (props.onPressOverride) {
                props.onPressOverride();
                return;
            }

            if (props.isTransparent) {
                return;
            }

            if (props.title) {
                var globalState = require('../../assets/GlobalState');
                var newScreenTitle = props.title;
                globalState.currentScreenTitle = newScreenTitle;
            }
            if (props.collection && props.document) {
                var globalState = require('../../assets/GlobalState');
                var documentName = props.document;
                globalState['DBPath'][props.collection] = documentName;
            }
            if (destination != '' || destination.length > 0) {
                navigation.navigate(destination, props.navigationData); 
            }
        }
        } disabled={disabled} style={[styles.cardTouchableOpacity, props.customStyle.cardTouchableOpacity, customShadowStyle.cardTouchableOpacity]}>
            <View style={[styles.cardContainerStyle, cardContainerStyle.cardContainerStyle]}>
                <View style={styles.cardView}>
                    <Image
                        style={styles.cardImageStyle}
                        activeOpacity={0.9}
                        resizeMode="cover"
                        source={props.image} />
                    <View style={styles.titleRow}>
                        <View>
                            <View style={styles.titleRow}>
                                <Text style={styles.cardHeaderTextStyle}>{props.title}</Text>
                                <Icon disabled={!props.highlight} disabledStyle={{ display: 'none' }} name='play-arrow' color='#FFF' />
                            </View>
                            <Text style={styles.cardDescriptionTextStyle}>{props.description}</Text>
                                
                        </View>
                        <Icon disabled={!isReward} disabledStyle={{ display: 'none' }} name='monetization-on' color='#FFBF00' iconStyle={{ marginRight: windowWidth * 0.02 }} />
                        <Image
                            disabled={props.tier == undefined || iconImage == undefined}
                            style={styles.tieredAchievementImageStyle}
                            activeOpacity={1}
                            resizeMode="contain"
                            source={iconImage} />
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    )
};