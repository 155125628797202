import { StyleSheet, Text, View } from 'react-native';
import React from 'react';

import { styles } from '../../styles/Standard/game/QuestionAnsweredBanner';

export function QuestionAnsweredBanner(props) {
	const result = (props.result != undefined) ? props.result : "";

	const updatedTextStyle = StyleSheet.flatten([
		styles.roundBackground,
		{
			backgroundColor : (props.result != undefined && props.result === "correct") ? "#1FC75A" : "#FF5454",
			display : (props.result != undefined && props.result === "unanswered") ? "none" : "flex"
		}
	])

	return (
		<View style={styles.view}>
			<View style={updatedTextStyle}>
				<Text style={styles.text}> {(props.result != undefined && props.result === "correct") ? "Whoohoo, well done  " : "Oops  "} 
				{(props.questionScore != undefined && props.questionScore >= 0) ? props.questionScore : ""}
				 {(props.questionMaxScore != undefined && props.questionMaxScore > 0) ? "/"+ props.questionMaxScore : ""} 
				</Text>
			</View>
		</View>
	);
}


