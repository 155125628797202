import { Alert } from "react-native"

export function checkProfileFields(profile) {
    var isValid = true
    if (profile != undefined) {
        isValid = false
    } 
    if (profile.userInfo != undefined) {
        isValid = false
        } 
        if (profile.achievements != undefined ) {
            isValid = false
        } 
        if (profile.gameStats != undefined) {
            isValid = false
        } 
        if (profile.gamesCompleted != undefined ) {
            isValid = false
        } 
        if (profile.unlistedGameStats != undefined) {
            isValid = false
    }
     return isValid
}