
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
// import { styles } from '../../styles/Standard/common/ProfileIcon.style';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements'
import { Dimensions } from 'react-native';

export function DoneButton() {
    const windowWidth = Dimensions.get('window').width
    const navigation = useNavigation();

    return (
        <TouchableOpacity onPress={
            () => {
                var gameState = require('../../assets/GameState')
                gameState.state.afterGameInfo = [];
                gameState.state.categoryScores = {};
                navigation.replace('dashboard');
            }
        }
            style={{ marginRight: windowWidth * 0.06 }}>
            <Text style={{ color: '#FFF', fontSize: 25, opacity: 0.87 }}>DONE</Text>
        </TouchableOpacity>
    )
}