import { StyleSheet, ScrollView, Text, Image, View, Dimensions, Alert, TouchableOpacity } from 'react-native';
import React, { useState, useRef, useEffect } from "react";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { styles } from '../../styles/Standard/common/MediaDisplay.styles'; //rename
import { useNavigation } from '@react-navigation/native';
import { WebView } from 'react-native-webview';
import { Video } from 'expo-av';
import { Platform } from 'react-native';
import { EmptyPageBackground } from '../../components/common/EmptyPageBackground'
import { LoadingIndicator } from './LoadingIndicator';
import { setIsEnabledAsync } from 'expo-av/build/Audio';
import RnPdfReader from '../RnPdfReader/RnPdfReader';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height



export function MediaDisplay(props) {
    var globalState = require('../../assets/GlobalState')
    let [canPlayGame, setCanPlayGame] = useState(true);

    useEffect(() => {
        if (props.mustWatchVideo) {

            if (Platform.OS == "web") {
                setCanPlayGame(false);
            }
            else {
                if (props.updateHasWatched) {
                    props.updateHasWatched(true);
                }
            }
        }
    },[])

    const navigation = useNavigation();
    navigation.setOptions({title: globalState.currentScreenTitle})

    if (globalState.DBPath.departmentId == undefined) {
        console.log("globalstate undefined after refresh")
        navigation.replace("login")
    }

    var media = []
    if (props.media != undefined) {
        media = props.media
        console.log(media);
    } else {
        console.log("media is undefined")
    }

    var isLoading = false
    if (props.isLoading != undefined) {
        isLoading = props.isLoading
    }

    var isEmptyPage = false
    if (props.isEmptyPage != undefined) {
        isEmptyPage = props.isEmptyPage
    }

    if (isEmptyPage) {
        return (<EmptyPageBackground isEmpty={isEmptyPage} />)
    }

    if (isLoading) {
        return (<LoadingIndicator isLoading={true} />)
    }


    const enableGame = (e) => {
        setCanPlayGame(true);

        if (props.updateHasWatched) {
            props.updateHasWatched(true);
        }
    }

    // might only owrk on andoird & ios not web
    function renderWebView() {
        if (globalState.isWebDisplay) {
            return (
                <View style={styles.videoContainer} key={i}>
                    <Text style={styles.text}>
                    </Text>
                </View>
            )
        } else {
            return (
                <WebView
                    originWhitelist={['*']}
                    source={{ uri: 'http://www.google.com' }}
                    style={{ marginTop: 5, height: 500, width: 1000, minHeight: 400 }}
                />)
        }

    }

    function renderLearningMedia(mediaArray) {
        if (mediaArray == undefined) {
            Alert.alert("Subtopic media is undefined. To address this, go back to Learning Center to reload or notify the department manager")
            return null
        }

        if (mediaArray.length < 1) {
            return null
        }

        return (mediaArray.map((media, i) =>
            (renderMediaItem(media, i)
            )
        ))


    }

    function IosVideoPlayer(props) {
        const [shouldPlay, setShouldPlay] = useState(false);
        const videoRef = useRef();

        return (
            <View>
                <Video
                    {...{
                        source: { uri: props.videoUrl },
                        resizeMode: "stretch",
                        useNativeControls: true,
                        style: styles.AzureView,
                        shouldPlay: shouldPlay,
                        ref: ref => videoRef.current = ref

                    }}
                />
                {!shouldPlay && <View style={[StyleSheet.absoluteFill]}>
                    <TouchableOpacity onPress={() => { setShouldPlay(true); } }
                        style={{ justifyContent: 'center', alignItems: 'center', flex: 1, flexDirection: "row" }}
                    >
                        <Image
                            source={require("../../assets/images/play_symbol.png")}
                            style={{
                                opacity: 0.5,
                                alignSelf: 'center',
                                flex: 1,
                                minHeight: 0.01 * Dimensions.get('window').height,
                                minWidth: 0.01 * Dimensions.get('window').width,
                                maxHeight: 0.2 * Dimensions.get('window').height,
                                maxWidth: 0.3 * Dimensions.get('window').width,
                            }}
                        />
                    </TouchableOpacity>
                </View>
                }
            </View>
        )
    }

    function videoPlaybackFeedback(status) {

        if (status.isPlaying && !canPlayGame) {
            enableGame();
        }

    }

    function renderMediaItem(media, i) {
        var webContainerWidth = windowWidth * 0.6
        var containerWidth = globalState.isWebDisplay ? webContainerWidth : windowWidth
        const [showFullscreenPdf, setShowFullscreenPdf] = useState(false)
        var dynamicContainerStyle = StyleSheet.create({
            textContainer: {
                minWidth: containerWidth,
                maxWidth: containerWidth,
            },
            videoContainer: {
                minWidth: containerWidth,
                maxWidth: containerWidth,
            },
        })

        if (media.mediaType == "video" || media.mediaType == "Video") {
            if (media.media == undefined || media.media.length < 3) {
                if (media.media.substring(0, 30) != 'https://www.youtube.com/embed/' || media.media.substring(0, 31) != 'https://player.vimeo.com/video/') { //|| media.media.substring(0, 25) != azureVideoUrlPrefix) {
                    console.log(media.media.substring(0, 30))
                    return (
                        <View style={styles.videoContainer} key={i}>
                            <Text style={styles.text}>
                                {"The video url is invalid.\n The required prefix is https://www.youtube.com/embed/ or https://mediagameondev-sa"}
                            </Text>
                        </View>
                    )
                }
            }

            let videoWidth = Dimensions.get('window').width;
            let videoHeight = windowWidth / 11 * 9;

            const isAzure = media.processingCompleted != undefined ? true : false
            if (isAzure) {
                const invalue = 1
                if (Platform.OS == "ios" || Platform.OS == "android") {
                    return (
                        <IosVideoPlayer
                            videoUrl={media.media}
                        />
                    )
                } else {

                    return (
                        <View style={styles.videoContainer} onClick={enableGame}>
                            <Video
                                source={{ uri: media.media }}
                                rate={1.0}
                                volume={1.0}
                                isMuted={false}
                                resizeMode="cover"
                                videoStyle={styles.innerVideoContainer}
                                useNativeControls={true}
                                onPlaybackStatusUpdate={videoPlaybackFeedback}
                            />
                        </View>
                    )
                }
            } else {

                if (globalState.isWebDisplay) {
                    let id = media.media
                    //check if the id contains anything to do with youtube
                    console.log("HERE IS THE ID : : : : : :", id);
                    if (id.includes("youtube")) {
                        id = "https://www.youtube.com/embed/" + media.media.split("/")[media.media.split("/").length - 1]

                    }
                    //check if the id contains anything to do with vimeo
                    if (id.includes("vimeo")) {
                        id = "https://player.vimeo.com/video/" + id.split("/")[id.split("/").length - 1]
                    }
                    return (

                        <iframe width={webContainerWidth} height={(9 / 16 * webContainerWidth)} src={id} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    )
                } else {
                    return (
                        <View style={styles.videoContainer} key={i} onClick={enableGame}>
                            <WebView
                                style={styles.WebView}
                                javaScriptEnabled={true}
                                domStorageEnabled={true}
                                source={{ uri: media.media }}
                                onLoad={enableGame}
                                key={"v" + i}
                            />
                        </View>
                    )
                }
            }
        }

        if (media.mediaType == "text" || media.mediaType == "Text") {
            return (
                <View style={[styles.textContainer, dynamicContainerStyle.textContainer]} key={i}>
                    <Text style={styles.text}>
                        {media.media}
                    </Text>
                </View>
            )
        }

        if (media.mediaType == "image" || media.mediaType == "Image") {
            var img = { uri: media.media }
            return (
                <View style={[styles.videoContainer, dynamicContainerStyle.videoContainer]} key={i}>
                    <Image
                        style={styles.image}
                        activeOpacity={1}
                        resizeMode="contain"
                        source={img}
                    />
                </View >
            )
        }

        if (media.mediaType == "pdf" || media.mediaType == "Pdf" || media.mediaType == "PDF") {

            var viewerUrl = "https://docs.google.com/gview?embedded=true&url=";
            var pdfUrl = media.media;

            if (globalState.isWebDisplay) {

                var pdfUrl = media.media;
                viewerUrl = "";
                const viewerProperties = "#toolbar=0&navpanes=0";

                return (
                    <View >
                        <div dangerouslySetInnerHTML={{ __html: '<script type="text/javascript">  document.onmousedown = disableRightclick;  var message = "Right click not allowed !!";  function disableRightclick(evt){ if(evt.button == 2){ alert(message); return false;  } }  </script>' }}></div>
                        <div dangerouslySetInnerHTML={{ __html: '<iframe width="' + webContainerWidth + '" height="' + (9 / 16 * webContainerWidth) + '" src="' + viewerUrl + pdfUrl + viewerProperties + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}></div>
                    </View>
                );

            } else {

                const url = JSON.parse(JSON.stringify(media.media))
                const globalColours = require('../../styles/globalColours')

                return (
                    <View style={styles.videoContainer} key={i}>
                        <RnPdfReader
                            style={{ height: (showFullscreenPdf ? 0.8 : 0.2) * windowHeight, width: windowWidth, backgroundColor: '#000' }}
                            source={{ uri: url }}
                            key={i}
                        />
                        {/* <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <TouchableOpacity 
                                onPress={() => { setShowFullscreenPdf(!showFullscreenPdf) }}
                                style={{ backgroundColor: globalColours.primaryColour, borderRadius: wp(5), paddingHorizontal: 0.05 * windowWidth, marginTop: 0.005 * windowHeight }}
                            >
                                <Text style={{ color: '#FFF', fontSize: 25, opacity: 0.87 }}>{showFullscreenPdf ? "Hide" : "Show"}</Text>
                            </TouchableOpacity>
                        </View> */}
                    </View>
                );

            }
        }

    }

    return (
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContentContainer} currentScreenTitle={globalState.currentScreenTitle}>
            <View style={{ maxWidth: globalState.isWebDisplay ? 0.6 * windowWidth : windowWidth }}>

                {renderLearningMedia(media != undefined ? media : [])}
            </View>
        </ScrollView>
    )
}
