import { StyleSheet } from 'react-native';

import { primaryColour } from '../globalColours'

import { widthPercentageToDP as wp, heightPercentageToDP as hp, heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import { isPortraitView } from '../../utils/isPortraitView'

import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height


export var styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#fff',
    },
    standardHeader: {
        backgroundColor: primaryColour,
        
        // color: '#FFF',
    },
//     standardHeaderTitle: {
// backgroundColor: '#000'
//     },
    gameHeader: {
    },
    profileHeader: {
        backgroundColor: primaryColour,
    },
    gameOnLogoContainer: {
        position: "absolute",
        top: -windowHeight * 0.04,
        left:0,
        justifyContent: 'center',
        alignItems: 'center',
        // width: windowWidth,
        width: '100%',
        height: windowHeight * 0.08,
        // height: '100%',
        // backgroundColor: '#F00',
        margin: 0,
        // border: 0,
        padding:0,
        // maxHeight: 300,
    },
    gameOnLogo: {
        // flex: 1,
        // minHeight: heightPercentageToDP(1),
        // maxHeight: heightPercentageToDP(10),
        // maxWidth: widthPercentageToDP(31),
        // maxHeight: heightPercentageToDP(5),
        width: '50%',
        height: '100%',
        marginHorizontal: 0,
        marginVertical: 0,
        // backgroundColor: '#000',
        paddingHorizontal: 0,
        // maxHeight: 300,
    },
    rowContainer: {
        flexDirection: 'row'
    },
    righHeaderStyle: {
        color: '#FFFFFF',
        fontSize: 20,
        fontWeight: "bold",
        alignSelf: "center"
    }

});

export var rawStyles= {
    standardHeader: {
        backgroundColor: primaryColour,
        minHeight: 0.07 * windowHeight,
        // fl
        // color: '#FFF',
        // flex:1
        // flexD

    },
    standardHeaderTitle: {
        color: '#FFFFFF',
        opacity: 0.87,
        fontSize: wp(6)
        // Todo scale for app
        // fontWeight: 'bold',
    },
}