
import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { heightPercentageToDP } from 'react-native-responsive-screen';
// import { widthPercentageToDP, heightPercentageToDP } from 'react-native-responsive-screen'


const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

//make colour setting in style sheet a props parameter and put in main function

// if more flex help needed look at: https://stackoverflow.com/questions/43143258/flex-vs-flexgrow-vs-flexshrink-vs-flexbasis-in-react-native/43147710#43147710
export const styles = StyleSheet.create({
    button: {
        // backgroundColor: "#03031c",
        flex: 1,
        borderRadius: windowHeight * 0.05,
        // marginHorizontal: ' 3%',
        width: '100%',
        marginBottom: windowHeight * 0.012,
        marginVertical: 0,
        alignItems: "center",
        justifyContent: "center",
        // textAlignVertical: 'middle',
        // width: widthPercentageToDP('45'),
        // height: heightPercentageToDP('45')

        // Shadow:
        // shadowOffset: {
        //     width: windowWidth*0.04,
        //     height: windowHeight * 0.01,
        //     blur: 20, // scale
        //     //scale these
        // },
        // elevation: windowHeight * 0.01,
        // shadowRadius: windowHeight * 0.004,
        // shadowOpacity: 0.75,
        // paddingBottom: windowHeight* 0.004,
        // marginBottom: windowHeight* 0.02
    },
    buttonText: {
        textAlign: "center",
        // textAlignVertical: "center",
        fontSize: heightPercentageToDP(5),
        fontWeight:'500',
        color: '#FFF',
        opacity:0.87,
        alignItems: 'center',
        overflow: 'hidden',
        marginTop:0,
        // flex: 1,
        // width:
        // marginTop: windowHeight * 0.002,
        // justifyContent: "center",
        // marginVertical:'10%',
        // flexWrap: 'wrap'
        // flexShrink: 1,
        // alignSelf: 'center',
        // wordWrap: 'break-word',
        // hyphens: 'auto',
    },
    thickShadowView: {
        flex: 1,
        borderRadius: windowHeight * 0.05,
        marginHorizontal: ' 3%',
        marginVertical: 0,
        alignItems: "center",
        justifyContent: "center",
        marginTop: heightPercentageToDP(2),
        marginBottom: windowHeight* 0.01,

        // shadowOffset: {
        //     width: windowWidth*0.04,
        //     height: windowHeight * 0.01,
        //     blur: 20, // scale
        //     //scale these
        // },
        // elevation: windowHeight * 0.01,
        // shadowRadius: windowHeight * 0.004,

        // shadowOffset: {
        //     width: 0,
        //     height: windowHeight * 0.002
        //     //scale these
        // },
        // // elevation: windowHeight * 0.005,
        // elevation: windowHeight * 0.01,
        // shadowRadius: windowHeight * 0.004,

        // shadowOpacity: 0.75,
        // paddingBottom: windowHeight* 0.004,
        // backgroundColor: "#FFF",
    }
})