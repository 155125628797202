
import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
// import { widthPercentageToDP, heightPercentageToDP } from 'react-native-responsive-screen'

import { primaryColour } from '../../globalColours'

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { isPortraitView } from '../../../utils/isPortraitView'

const portraitView = isPortraitView()
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

//make colour setting in style sheet a props parameter and put in main function

// if more flex help needed look at: https://stackoverflow.com/questions/43143258/flex-vs-flexgrow-vs-flexshrink-vs-flexbasis-in-react-native/43147710#43147710
export const styles = StyleSheet.create({
    text: {
        // marginRight: windowWidth * 0.06,

        // top: 100,
        // minHeight: 0.1,
        // maxHeight: 0.1,
        // height: 100,
        // left: windowWidth * 0.45
        color: '#FFF',
        opacity: 0.86,
        marginBottom: windowHeight * 0.02,
    },
    container: {
        position: 'absolute',
        top: windowHeight * 0.32,
        left: windowWidth * 0.37,
        flex: 1,
        width: portraitView ? hp(15) : hp(30),
        height: portraitView ? hp(15) : hp(10),
        borderRadius: hp(10),
        // top: 400,
        justifyContent: "center",
        alignItems: 'center',
        backgroundColor: primaryColour
        // width: windowWidth * 0.225,
        // width: windowWidth * 0.225,
        // minHeight:  0.1 * windowHeight,
        //    maxHeight: 0.1 * windowHeight,

    }
});

export var rawStyles = {
    text: {
        // marginRight: windowWidth * 0.06,

        // top: 100,
        // minHeight: 0.1,
        // maxHeight: 0.1,
        // height: 100,
        // left: windowWidth * 0.45
        color: '#FFF',
        opacity: 0.86,
        marginBottom: windowHeight * 0.02,
    },
    container: {
        position: 'absolute',
        top: windowHeight * 0.32,
        left: windowWidth * 0.37,
        flex: 1,
        width: portraitView ? hp(15) : hp(30),
        height: portraitView ? hp(15) : hp(10),
        borderRadius: hp(10),
        // top: 400,
        justifyContent: "center",
        alignItems: 'center',
        backgroundColor: primaryColour
        // width: windowWidth * 0.225,
        // width: windowWidth * 0.225,
        // minHeight:  0.1 * windowHeight,
        //    maxHeight: 0.1 * windowHeight,

    }
}