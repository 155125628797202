import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { heightPercentageToDP  as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { isPortraitView } from '../../../utils/isPortraitView'

const portraitView = isPortraitView()

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

export var styles = StyleSheet.create({
	standardHeaderTitle: {
		fontSize: hp(4)
	},
	webContainer: {
		minWidth: portraitView? windowWidth: wp(60),
		maxWidth: portraitView? windowWidth: wp(60),
		height: '100%',
		flex: 1,
	},
	fullContainer: {
		maxWidth: '100%',
		minWidth: '100%',
		alignItems: "center",
		justifyContent: 'center',
		flexDirection: 'row',
		flex: 1,
		minWidth: windowWidth,
		maxWidth: windowWidth,
	},
	scrollView: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
	},
	text: {
		minHeight: windowHeight * 0.1,
		flex: 1,
		marginHorizontal: windowWidth * 0.04,
		fontSize: 22,
	},
	image: {
        flex: 1,
		marginBottom: windowHeight * 0.01,
		width: '100%',
		minWidth: windowWidth,
        minHeight: windowHeight * 0.04,
		maxWidth: '100%',
	},
	videoContainer: {
		flex: 1,
		minWidth: windowWidth * 0.5,
		minHeight: windowHeight * 0.4,
		backgroundColor: '#FFF'
	},
	textContainer: {
		minWidth: windowWidth * 0.7,
		maxWidth: windowWidth * 0.7,
		flex: 1
	},
	textAlreadyPlayed: {
		color: '#000',
		opacity: 0.87,
		// fontSize: adjustFontSize(16),
		fontSize: hp('2.50%'),
		marginHorizontal: windowWidth * 0.07,
		flex: 0.5,
		flexShrink: 1,
		flexBasis: 0,
		maxHeight: windowHeight * 0.1,
		minHeight: windowHeight * 0.1,
		textAlign: "center",
		textAlignVertical: "center",
		// backgroundColor: "#000",
		// justifyContent: "center"
		marginBottom: windowHeight * 0.02,
		overflow: 'hidden'
	},
});