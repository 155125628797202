import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { heightPercentageToDP } from 'react-native-responsive-screen';
import {isPortraitView} from '../../../utils/isPortraitView'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const cardsContainerWidth =  (windowWidth / windowHeight > 1) ? 0.5 * windowHeight * 1.2 : windowWidth

const globalState = require('../../../assets/GlobalState')
var portraitView = isPortraitView()

export var styles = StyleSheet.create({
    profileContainer: {
        // maxWidth: cardsContainerWidth,
        // backgroundColor: '#000'
    },
	avatar: {
		// color: '#000',
		// // opacity: 0.87,
		// fontSize: 50,
		// marginHorizontal: windowWidth * 0.07,
		// marginTop: windowHeight * 0.03,
		// flex: 1,
        // flexShrink: 1,
        // minHeight: 100
		// flexBasis: 0,
		// maxHeight: windowHeight * 0.2,
        // minHeight: windowHeight * 0.2,
        // // maxWidth: windowWidth * 0.4,
		// minWidth: windowWidth * 0.1,
        // backgroundColor: "#FF5454",
        // color: '#FFF',
        // borderRadius: windowHeight * 0.03,
        // paddingHorizontal: windowWidth * 0.03,
        // paddingVertical:  windowHeight * 0.001
        // fontWeight: '800'
        // fontFamily: "Roboto-heavy"
		// justifyContent: "center"
		// marginBottom: windowHeight * 0.02,
		// overflow:'hidden'
    },
    logoContainer: {
        maxHeight: windowHeight * 0.3,
        minHeight: windowHeight * 0.3,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    dashboardContainer: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
    },
    loginButton: {
        maxHeight: windowHeight * 0.1,
    },
    listContainerStyle: {
        maxHeight: windowHeight * 0.4,
        minHeight: windowHeight * 0.2,
        // minWidth: windowWidth * 0.85,
        width: '100%',

        marginTop: portraitView?windowHeight*0.0:0,
        marginBottom: 0,
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        // backgroundColor: '#000',
    },
    forgotPasswordText: {
        marginTop: windowHeight * 0.02,
        color: '#DB3C6C'
    },
    wideContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#000',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		flexWrap: 'wrap',
		minWidth: portraitView?windowWidth : cardsContainerWidth ,
        maxWidth: portraitView ? windowWidth : cardsContainerWidth,
        // backgroundColor: '#000'
	},
});

export const inputContainerStyle = {
    borderWidth: windowHeight * 0.003,
    borderColor: '#00AFFF',
    borderRadius: windowHeight * 0.03,
    marginHorizontal: windowWidth * 0.05,
    overflow: 'hidden',
    // maxWidth: windowWidth * 0.85,
    maxWidth:  portraitView ? windowWidth* 0.9 :cardsContainerWidth * 0.75,
    minWidth: portraitView ? windowWidth* 0.9 :cardsContainerWidth * 0.75,
    marginVertical: windowHeight * 0.01,
    minHeight: windowHeight * 0.07,
    maxHeight: windowHeight * 0.07,
    paddingVertical: windowHeight * 0.005,
    // fontSize: heightPercentageToDP(2)
    // marginTop: 10
    // backgroundColor: '#000'
}

export const buttonColor = '#FF5488'
export const buttonShadowColor = '#DB3C6C'

export const loginButtonStyle = StyleSheet.create({
    button: {
        backgroundColor: "#FF5454",
        shadowColor: '#FF5653', 
        maxHeight: 1
    }
});
