import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import {isPortraitView} from '../../../utils/isPortraitView'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing
const portraitView = isPortraitView()
const cardsContainerWidth =  (!portraitView && (windowWidth / windowHeight > 1)) ? 0.5 * windowHeight * 1.2 : windowWidth

export var styles = StyleSheet.create({
	list: {

	},
	background: {
		height: '100%',
		backgroundColor: '#FFF',
		// width: '100%',
		//minHeight: windowHeight * 0.8,
	},
	wideContainer: {
		justifyContent: 'center', // web
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		//minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#000',
		//justifyContent: 'flex-start',
		// alignItems: 'space-between',
		flexDirection: 'column',
		flexWrap: 'wrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
	},
});

// use start en end radius for the smooth

