import { Dimensions, View, Alert, Text, Platform } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import React, { useState, useEffect } from "react";
import { ListItem } from 'react-native-elements';
import { heightPercentageToDP } from 'react-native-responsive-screen';

import { styles } from '../styles/Standard/screens/GameCategories.styles'; 
import { getElement } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';

import {EmptyPageBackground} from '../components/common/EmptyPageBackground'
import { useRef } from 'react';
import gameState from "../assets/GameState";

const windowWidth = Dimensions.get('window').width


//  if this is not killed when navigating to game, the prvious categorylist survives and it wont get a new list of catgories
export function GameCategories({ navigation }, props) {
	
	const [gameCategories, setGameCategories] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [hasMedia, setHasMedia] = useState(false)
	const [allUserAnswers, setAllUserAnswers] = useState([])
	const [timesPlayed, setTimesPlayed] = useState(0)
	const globalState = require('../assets/GlobalState')
	const isFocused = useIsFocused();
	const hasPlayedSessionRef = useRef(false);
	const isNavigatingForward = useRef(false);

	var gameState = require('../assets/GameState')
	const ALLOWED_CATEGORIES_PER_DAY = globalState.userGameRetryOverride > 1 ? globalState.userGameRetryOverride : gameState.gameInfo.categoryRetries ? parseInt(gameState.gameInfo.categoryRetries) : 0;
	navigation.setOptions({ title: globalState.currentScreenTitle })
	
	useEffect(() => {
		
		// Add hook before leaving the screen
		const navListener = navigation.addListener('beforeRemove', (e) => {
			
			// No need for prompting user if no category was successfully completed
			if (!hasPlayedSessionRef.current || !isFocused) {
				return;
			}
			
			// No need for prompt if the user is navigating 'forward'
			if (isNavigatingForward.current === true) return;
			
			e.preventDefault();

			const cleanGameState = () => {
				gameState.state.afterGameInfo = [];
				gameState.state.categoryScores = {};
				gameState.state.uncompletedCategories = [];
			};

			// Prompt user before leaving the screen
			if (Platform.OS === "web") {
				
				// Need to use the JS native confirm since the react-native Alert does not work on web yet (2021-12-21)
				if (confirm("You will lose your current progress if you leave this screen. Discard progress?")) {
					cleanGameState();
					navigation.dispatch(e.data.action);
				}

			} else {

				Alert.alert(
					"Discard progress?",
					"You will lose your current progress if you leave this screen. Continue?",
					[
						{ text: "Stay", style: "cancel", onPress: () => {} },
						{ text: "Leave", style: "destructive", onPress: () => {
							cleanGameState();
							navigation.dispatch(e.data.action);
						} }
					]
				);

			}

		});

		/* 
			Need to return the cleanup functiun of the event listener
		*/
		return navListener;

	}, [ navigation ]);

	useEffect(() => {

		let answers = getElement(['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId, 'Answers'])
			.get();

		answers.then(results => {
			var answers = []

			results.docs.map(doc => {
				var answer = doc.data()
				answer.id = doc.id;

				answers.push(answer);
			});

			setAllUserAnswers(answers);
			
		});

	}, [isFocused])

	useEffect(() => {
		getGameCategories();
	}, [allUserAnswers]);

	function getHighestScore(categoryId) {

		var maxScore = 0;
		var hasPlayed = false;
		var timesPlayedToday = 0;
		
		for (var answerKey in allUserAnswers) {

			let answerData = allUserAnswers[answerKey];
			
			if (answerData?.gameId === globalState.DBPath.gameId && answerData?.categoryId === categoryId) {

				hasPlayed = true;

				//Set times played today
				const currentDate = new Date();
				var strDate = currentDate.getFullYear() + '_' + currentDate.getMonth() + '_' + currentDate.getDate();

				if (answerData.id.indexOf(strDate) > -1) {
					timesPlayedToday++;
				}

				var score = 0;

				for (var key in answerData.answersCorrect) {
					score += answerData.answersCorrect[key];
				}

				if (score > maxScore) {
					maxScore = score;
                }
			}
		}

		return { maxScore: maxScore, hasPlayed: hasPlayed, timesPlayedToday: timesPlayedToday};
	}

	function getGameCategories() {

		if (!globalState?.DBPath?.companyId || !globalState?.DBPath?.departmentId || !globalState?.DBPath?.gameId) {
			navigation.replace('login');
			return null;
		}

		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Games', globalState.DBPath.gameId, 'Categories']

		getElement(path).get().then(function (docs) {

			if (docs != undefined && docs.docs != undefined) {
				var topics = []
				var rawDocs = []
				var lastGamePlayedIndex = -1;

				docs.docs.map((doc, idx) => {
					var item = doc.data();
					item.id = doc.id;

					rawDocs.push(item);
				});

				// The categories need to be played in order
				rawDocs.sort((catA, catB) => {
					if (catA.order !== undefined) {
						return catA.order < catB.order ? -1 : catA.order > catB.order ? 1 : 0;
					}

					return catA.name < catB.name ? -1 : catA.name > catB.name ? 1 : 0;
				});

				rawDocs.map((doc, idx) => {

					var newTopic = doc;
					var highestScore = getHighestScore(doc.id);

					newTopic.canPlay = false;
					newTopic.hasPlayed = false;
					newTopic.documentId = doc.id;
					newTopic.maximumGameScore = getMaxCategoryScore(newTopic);
					newTopic.passRate = parseInt(newTopic.passRate ?? '0');

					// Determine from the gameState if this category has already been played in this session
					let sessionAnswers = gameState?.state?.afterGameInfo?.filter(i => i.categoryId == newTopic.documentId);

					if (sessionAnswers?.length > 0) {
						// This category has already been played in this session, calculate its score and check if it's passed
						let sessionScore = sessionAnswers.reduce((prev, curr) => curr?.isCorrect ? ++prev : prev, 0);
						let sessionScorePerc = (sessionScore / newTopic.maximumGameScore) * 100;

						if (sessionScorePerc >= newTopic.passRate) {
							// This category has passed, adjust the lastGamePlayedIndex
							lastGamePlayedIndex = idx;
							newTopic.hasPlayed = true;
							hasPlayedSessionRef.current = true;
						}
					}

					// Allow the category to be played if it's the next one in line
					if (idx == (lastGamePlayedIndex + 1)) {
						newTopic.canPlay = true;
						setTimesPlayed(highestScore.timesPlayedToday);
					}

					topics.push(newTopic)

					if (newTopic.media && newTopic.media.length > 0) {
						setHasMedia(true);
					}

				})
				
				setGameCategories(topics)
				setIsLoading(false)
				
			} else {
				console.log("Document not found");
			}

		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
	}

	function getMaxCategoryScore(category) {
		if (category == undefined || category.questions == undefined) {
			return 0;
		}

		var questions = category.questions;
		var questionKeys = Object.keys(questions);
		var numQuestions = (Object.keys(questionKeys)).length;

		return numQuestions > 5 ? 5 : numQuestions;
    }

	function isEmptyPage() {
		return !isLoading && (!gameCategories || gameCategories.length == 0)
	}

	function getStyle(category) {
		if (!category.canPlay) {
			return { width: '100%', padding: 0, backgroundColor: 'lightgrey' };
		}

		return { width: '100%', padding: 0 };
    }

	function getUnplayedCategories() {

		let catIDs = [];

		gameCategories.map(cat => {
			if (!cat.hasPlayed) {
				catIDs.push(cat.documentId);
			}
		});

		return catIDs;

	};

	return (
		<View style={styles.background}>
			<View style={styles.wideContainer}>
				{
					((ALLOWED_CATEGORIES_PER_DAY == 0) || (timesPlayed <= ALLOWED_CATEGORIES_PER_DAY))
					?
					<View style={styles.cardsContainerContent}>
						{
							gameCategories.map((l, i) => (
								<ListItem
									key={i}
									title={l.name}
									titleStyle={{ fontSize: heightPercentageToDP(3) }}
									subtitle={(l.dueDate != undefined) ? l.subtitle + '\n' + l.dueDate : l.subtitle}
									subtitleStyle={{ fontSize: globalState.portraitView ? heightPercentageToDP(2.5) : heightPercentageToDP(2), marginVertical: 0 }}
									containerStyle={getStyle(l)}
									contentContainerStyle={{ marginLeft: 0.05 * windowWidth, width: '100%', paddingVertical: heightPercentageToDP(0.5), marginVertical: 0 }}
									chevron={true}
									onPress={() => {
										
										if (!l.canPlay) {
											return;
										}

										if (l.questions != undefined && Object.keys(l.questions).length > 0) {
											var categoryId = l.documentId;
											globalState['DBPath']['gameCategoryId'] = categoryId;

											gameState.categoryInfo = l;
											gameState.state.uncompletedCategories = getUnplayedCategories();
											gameState.state.startTime = new Date()
											gameState.live = 0
											gameState.isLeaderboardUpdated = true
											gameState.maximumGameScore = 0
											
											hasPlayedSessionRef.current = false;
											isNavigatingForward.current = true;

											/*
												This navigation has to be a replace otherwise the user can return
												to the categories screen and manipulate the scoring of the games
											*/

											if (hasMedia) {
												navigation.replace('game_category_intro')
											}
											else {
												navigation.replace('game')
											}

										} else {
											Alert.alert("Category does not have questions.")
										}
									}}
									bottomDivider
								/>
							))
						}
					</View>
					:
					<View style={styles.textContainer}>
						<Text style={styles.textAlreadyPlayed}>You've reached the maximum amount of retries. Please check again tomorrow.</Text>
					</View>
				}
			</View>
			<LoadingIndicator isLoading={isLoading} />
			<EmptyPageBackground isEmpty={isEmptyPage()} />
		</View>
	);
}